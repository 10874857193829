import { useContext } from "react";
import { CreateDealContext } from "screens/create-deal/context";

export function useSecondarySellerSection() {
  const { currentDraft } = useContext(CreateDealContext);
  const data = currentDraft.secondary_seller;

  return {
    // SafeCast: this section is only visible on secondary drafts, see <ReviewSubmitStep />
    sellerName: data?.name as string,
    sellerEmail: data?.email as string,
  };
}
