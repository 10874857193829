import {
  getDealsGetDraftQueryKey,
  useDealsUpdateDraft,
} from "@internal/rest/generated/queries/drafts";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import {
  isFounderDraft,
  isSecondarySyndicateDeal,
} from "screens/create-deal/utils";
import { handleResourceEffect } from "utility/async";
import { useBooleanToggle } from "utility/hooks/useBooleanToggle";

import { CreateDealContext } from "../../context";
import {
  FormFields,
  FormValues,
  computeFormValuesToDealTerms,
  getDefaultFormValues,
} from "./utils";

const emptyTerms = "<p></p>";

export function useDealTermsStep() {
  const queryClient = useQueryClient();
  const createDealContext = useContext(CreateDealContext);
  const fetchedTerms = createDealContext.currentDraft.terms;
  const fetchedCustomTerms = fetchedTerms?.custom_terms ?? "";
  const [isSubmitting, toggleSubmitting] = useBooleanToggle();
  const [customTerms, setCustomTerms] = useState(fetchedCustomTerms);
  const { mutateAsync: updateDraft } = useDealsUpdateDraft();
  const isSecondary = isSecondarySyndicateDeal(createDealContext.currentDraft);
  const isFounder = isFounderDraft(createDealContext.currentDraft.pricing_plan);
  const defaultValues = getDefaultFormValues(createDealContext.currentDraft);

  async function handleSubmit(values: FormValues) {
    toggleSubmitting();

    await handleResourceEffect({
      async action() {
        await updateDraft({
          dealDraftId: createDealContext.draftId,
          data: {
            terms: computeFormValuesToDealTerms(
              values,
              isSecondary,
              isFounder,
              customTerms === emptyTerms ? "" : customTerms
            ),
            ...(values[FormFields.TargetClose]
              ? {
                  target_closing_at: new Date(
                    values[FormFields.TargetClose]
                  ).toISOString(),
                }
              : {}),
          },
        });

        await queryClient.resetQueries({
          queryKey: getDealsGetDraftQueryKey(createDealContext.draftId),
          exact: true,
        });

        createDealContext.onNextStep();
      },
      error: {
        title: "An unexpected draft problem occurred",
      },
      cleanup() {
        toggleSubmitting();
      },
    });
  }

  return {
    handleSubmit,
    handleSaveCustomTerms: setCustomTerms,
    customTerms,
    fetchedTerms,
    defaultValues,
    isSubmitting,
    isSecondaryDeal: isSecondary,
    isFounderDeal: isFounder,
  };
}
