import { RequestMiddleware } from "graphql-request";

const isExtractableFile = <ValueType,>(value: ValueType) => {
  return (
    (typeof File !== "undefined" && value instanceof File) ||
    (typeof Blob !== "undefined" && value instanceof Blob)
  );
};

export const fileUploadMiddleware: RequestMiddleware = (request) => {
  const hasInputVariableObj = !!request.variables?.["input"];

  const vars = hasInputVariableObj
    ? request.variables?.["input"]
    : request.variables;

  const files = Object.entries(vars || {}).flatMap(
    ([variableKey, variableValue]) => {
      if (isExtractableFile(variableValue)) {
        return [
          {
            variableKey: [
              hasInputVariableObj
                ? `variables.input.${variableKey}`
                : `variables.${variableKey}`,
            ],
            file: variableValue as File | Blob,
          },
        ];
      }

      // not hooked up nested variable objects support yet ⬇️

      // if (
      //   Array.isArray(variableValue) &&
      //   variableValue.every((item) => isExtractableFile(item))
      // ) {
      //   return variableValue.map((file, fileIndex) => {
      //     return {
      //       variableKey: [`variables.${variableKey}.${fileIndex}`],
      //       file,
      //     };
      //   });
      // }

      return [];
    }
  );

  if (!files.length) {
    // no files, return forward request as is
    return request;
  }

  // build multipart form data

  const form = new FormData();
  form.append("operations", request.body as string);

  const map = files.reduce((accumulator, { variableKey }, index) => {
    return {
      ...accumulator,
      [index.toString()]: variableKey,
    };
  }, {});

  form.append("map", JSON.stringify(map));

  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    if (!file?.file) continue;

    form.append(index.toString(), file.file);
  }

  const { "Content-Type": contentType, ...newHeaders } =
    request.headers as Record<string, string>;

  return {
    ...request,
    body: form,
    headers: newHeaders,
  };
};
