import { BaseCurrency } from "@internal/rest/generated/schemas";
import { getCurrencySign } from "@internal/utils/currency";
import { useFormContext } from "react-hook-form";

import { FormFields } from "../../utils";

export function useTotalRoundField() {
  const { watch } = useFormContext();
  const currency = watch(FormFields.Currency);

  return {
    currencySign: getCurrencySign(currency || BaseCurrency.gbp),
  };
}
