import { PricePlanName } from "@internal/rest/generated/schemas";
import { useFormContext } from "react-hook-form";
import { useBreakpoint } from "utility/hooks/useBreakpoint";
import { isBrandedPricePlan } from "utility/pricing";

import { FormFields } from "../../utils";

interface Args {
  onPricePlanChange: (pricePlan: PricePlanName) => void;
}

export function usePricePlans(args: Args) {
  const formContext = useFormContext();
  const { isMdScreenUp } = useBreakpoint("mdUp");

  function onSelectPricePlan(pricePlan: PricePlanName) {
    if (!isBrandedPricePlan(pricePlan)) {
      formContext.clearErrors(FormFields.EntityLegalName);
    }

    args.onPricePlanChange(pricePlan);
  }

  return {
    onSelectPricePlan,
    isMdScreenUp,
  };
}
