import { BaseCurrency, InvestmentType } from "@internal/rest/generated/schemas";
import { getCurrencySign } from "@internal/utils/currency";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CreateDealContext } from "screens/create-deal/context";
import { isSecondarySyndicateDeal } from "screens/create-deal/utils";

import { FormFields } from "../../utils";

export function useEquityFields() {
  const { currentDraft } = useContext(CreateDealContext);
  const { watch } = useFormContext();

  const currency = watch(FormFields.Currency);
  const investmentType = watch(FormFields.Instrument);
  const seis = watch(FormFields.SEIS);

  const isSecondary = isSecondarySyndicateDeal(currentDraft);
  const withSeis = (seis || []).length > 0 && !isSecondary;

  const isRightType =
    investmentType === InvestmentType.equity ||
    investmentType === InvestmentType.secondary;

  const isLoading = isSecondary
    ? typeof currency === "undefined"
    : [currency, seis, investmentType].some(
        (val) => typeof val === "undefined"
      );

  return {
    isVisible: isRightType && !isLoading,
    isSecondaryDeal: isSecondary,
    currencySign: getCurrencySign(currency || BaseCurrency.gbp),
    withSeis,
  };
}
