import { useState } from "react";

interface Args {
  initialPercent: number;
  onPercentChange: (percent: number) => void;
}

export function useRecipientInput(args: Args) {
  const [percent, setPercent] = useState<string | number>(args.initialPercent);

  function onPercentChange(val: string | undefined) {
    setPercent(val || "");
    args.onPercentChange(val ? Number(val) : 0);
  }

  return {
    percent,
    onPercentChange,
  };
}
