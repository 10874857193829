import { VStack } from "@@panda/jsx";
import { ProxyVotingTarget } from "@internal/rest/generated/schemas";
import { Form } from "components/forms/Form/Form";

import { FormFields, FormLabels } from "../../utils";
import { useProxyVotingField } from "./useProxyVotingField";

export function ProxyVotingField() {
  const { isFounderDeal } = useProxyVotingField();

  const subLabel = `Who will manage the governance of the ${
    isFounderDeal ? "SPV" : "syndicate"
  } and handle investor consent matters?`;

  return (
    <VStack>
      <Form.Select
        name={FormFields.ProxyVoting}
        label={FormLabels.ProxyVoting}
        subLabel={subLabel}
        invalidErrorMessage="Please select a proxy voting option"
        fieldOpts={{ required: true }}
        selectContainerStyle={{ maxWidth: "25rem" }}
      >
        {isFounderDeal ? null : (
          <option value={ProxyVotingTarget.syndicate_lead}>
            You, the Syndicate lead(s)
          </option>
        )}

        <option value={ProxyVotingTarget.chair_of_board}>
          Chair of the board
        </option>

        <option value={ProxyVotingTarget.founders}>
          Founder(s) of the investee company
        </option>
      </Form.Select>
    </VStack>
  );
}
