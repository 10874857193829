import { VStack, styled } from "@@panda/jsx";
import { FieldRow } from "components/display/FieldRow";
import { Typography } from "components/display/Typography/Typography";
import { parseStoredCountry } from "components/forms/Form/FormCountrySelect/utils";

import { useCompanySection } from "./useCompanySection";

interface Props {
  companyLogo: string;
}

export function CompanySection(props: Props) {
  const section = useCompanySection(props);

  return (
    <>
      <FieldRow
        label="Company logo"
        value={
          <styled.img
            src={section.companyLogo}
            width="8"
            height="8"
            bg="black"
            borderRadius="base"
          />
        }
      />
      <FieldRow label="Company legal name" value={section.companyLegalName} />
      <FieldRow label="Company trading name" value={section.companyName} />
      <FieldRow label="Company URL" value={section.companyUrl} />
      <FieldRow label="Company contact name" value={section.contactName} />
      <FieldRow label="Company contact email" value={section.contactEmail} />
      <FieldRow
        label="Country"
        testId="company-address-row"
        value={
          <VStack alignItems="flex-end" gap="1">
            <Typography variant="body">
              {section.companyAddress.firstLine}
            </Typography>

            {section.companyAddress.secondLine ? (
              <Typography variant="body">
                {section.companyAddress.secondLine}
              </Typography>
            ) : null}

            <Typography variant="body">
              {section.companyAddress.city}
            </Typography>

            <Typography variant="body">
              {section.companyAddress.state}
            </Typography>

            <Typography variant="body">
              {section.companyAddress.postCode}
            </Typography>

            <Typography variant="body">
              {parseStoredCountry(section.companyAddress.country)}
            </Typography>
          </VStack>
        }
      />
    </>
  );
}
