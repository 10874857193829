import { PricePlanName } from "@internal/rest/generated/schemas";
import {
  activeFounderPricePlan,
  activeSyndicatePricePlans,
} from "utility/pricing";

export const validateActivePricePlan = (
  plan: PricePlanName
): { ok: true; plan: ActivePricePlan } | { ok: false; plan: PricePlanName } => {
  const yes =
    activeSyndicatePricePlans.includes(plan) ||
    activeFounderPricePlan.includes(plan);

  if (yes) {
    return {
      ok: true,
      plan: plan as ActivePricePlan,
    };
  }

  return {
    ok: false,
    plan: plan,
  };
};

export type ActivePricePlan =
  | (typeof activeSyndicatePricePlans)[number]
  | (typeof activeFounderPricePlan)[number];

export function mapPricePlanNameInDisclaimer(pp: PricePlanName) {
  switch (pp) {
    case PricePlanName.institutional_2024:
      return "the Institutional";
    case PricePlanName.syndicate_plus_2024:
      return "the Syndicate Plus";
    default:
      return "this";
  }
}
