import { useSyndicatesList } from "@internal/rest/generated/queries/syndicates";
import {
  SyndicateLeadReference,
  SyndicateLeadReferenceList,
  SyndicateRelationshipFilterParameter,
} from "@internal/rest/generated/schemas";
import { useEffect, useMemo } from "react";
import { useAuth } from "services/auth/AuthProvider";

import { CREATE_NEW_SYNDICATE_OPTION, getSelectedSyndicate } from "../../utils";
import { filterUserFromSyndicateMembers } from "./utils";

export type Members = SyndicateLeadReference[];

interface Args {
  currentMembers: SyndicateLeadReferenceList;
  onResetMembers: (members: Members) => void;
  syndicateId: string;
}

export function useAdditionalMembers({ syndicateId, onResetMembers }: Args) {
  const { account } = useAuth();

  const syndicatesListResult = useSyndicatesList({
    relationship: SyndicateRelationshipFilterParameter.lead,
  });

  const userSyndicates = syndicatesListResult.data?.syndicates;

  // Get the selected syndicate from the list of syndicates the user
  // is a member and a lead
  const selectedSyndicate = useMemo(
    () => getSelectedSyndicate(userSyndicates, syndicateId),
    [syndicateId, userSyndicates]
  );

  const isSyndicateEditable =
    selectedSyndicate?.leads_mutability === "mutable" ||
    syndicateId === CREATE_NEW_SYNDICATE_OPTION;

  useEffect(() => {
    if (syndicateId && selectedSyndicate?.members?.length) {
      onResetMembers(
        filterUserFromSyndicateMembers({
          userId: account?.id || "",
          members: selectedSyndicate?.members,
        })
      );
    }
  }, [selectedSyndicate, syndicateId]);

  return {
    isLoading: !account || !syndicatesListResult.isSuccess,
    isSyndicateEditable,
  };
}
