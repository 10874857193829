import { InvestmentRound } from "@internal/rest/generated/schemas";

export function computeRoundTypeDisplayValue(
  round: InvestmentRound | undefined
) {
  switch (round) {
    case "bridge_to_series_a":
      return "Bridge to Series A";
    case "bridge_to_series_b":
      return "Bridge to Series B";
    case "bridge_to_series_c":
      return "Bridge to Series C";
    case "bridge_to_series_d":
      return "Bridge to Series D+";
    case "series_a":
      return "Series A";
    case "series_b":
      return "Series B";
    case "series_c":
      return "Series C";
    case "series_d":
      return "Series D+";
    case "pre_seed":
      return "Pre-seed";
    case "seed":
      return "Seed";
    default:
      return "";
  }
}
