import { SyndicateMemberList } from "@internal/rest/generated/schemas";

const CHAR_LIMIT = 40;

export function computeLeadName(name: string) {
  return name.length > CHAR_LIMIT
    ? name.slice(0, CHAR_LIMIT).concat("...")
    : name;
}

interface ComputeExistingSyndLeadsArgs {
  members: SyndicateMemberList;
  userId: string;
}

export function filterUserFromSyndicateMembers(
  args: ComputeExistingSyndLeadsArgs
) {
  return args.members
    .filter((member) => member.membership_type === "lead")
    .filter((lead) => lead.account_id !== args.userId)
    .map((lead) => ({
      first_name: lead.first_name,
      last_name: lead.last_name,
      email: lead.email as string,
    }));
}
