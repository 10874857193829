import { useContext } from "react";
import { CreateDealContext } from "screens/create-deal/context";

export function useHeroSection() {
  const { currentDraft } = useContext(CreateDealContext);
  const { company } = currentDraft;

  return {
    companyLegalName: company?.legal_name ?? "",
    companyName: company?.trading_name ?? "",
  };
}
