/* eslint-disable @typescript-eslint/no-restricted-imports */
import { initGraphQLTada } from "gql.tada";

import { introspection } from "../generated/graphql-env";
import { scalars } from "./scalars";

type Scalars = {
  [K in keyof typeof scalars]: (typeof scalars)[K]["inferableTypeForTada"];
};

export const graphql = initGraphQLTada<{
  introspection: introspection;
  scalars: Scalars;
}>();

export { readFragment } from "gql.tada";
export type {
  FragmentOf,
  ResultOf,
  TadaDocumentNode,
  VariablesOf,
} from "gql.tada";
