type Err = {
  message: string;
};

export const deriveGqlError = (err: unknown) => {
  let e = "";
  if (Array.isArray(err)) {
    const errObj = err[0] as Err;
    return errObj?.message ?? "";
  }
  return e;
};
