import { Box, HStack, VStack } from "@@panda/jsx";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { getCurrencySign } from "@internal/utils/currency";
import { Alert } from "components/feedback/Alert";
import { Form } from "components/forms/Form/Form";
import { Button } from "components/main/Button";
import { Dialog } from "components/main/Dialog";
import { StepSubmit } from "screens/create-deal/components/StepSubmit/StepSubmit";
import { validateAmount } from "screens/create-deal/utils";

import { AddRecipientModal } from "./components/AddRecipientModal/AddRecipientModal";
import { CarryRecipients } from "./components/CarryRecipients/CarryRecipients";
import { RecipientsSummary } from "./components/RecipientsSummary/RecipientsSummary";
import { FormFields, useCarryStep } from "./useCarryStep";

export const inputMaxWidth = "16rem";

export function CarryStep() {
  const {
    onSubmit,
    initialValues,
    baseCurrency,
    watchedFields,
    onFieldsChange,
    onOpenModal,
    onAddRecipient,
    onAddPercent,
    onRemoveRecipient,
    formValues,
    carryRecipients,
    arePercents100,
    isSubmitting,
  } = useCarryStep();

  const currencySign = getCurrencySign(baseCurrency);
  const withCarry = Number(formValues.defaultCarry || 0) > 0;
  const missingCarryRecipients = carryRecipients.length === 0 && withCarry;
  const invalidPercents = !arePercents100 && withCarry;

  return (
    <Dialog title="Add carry recipient" styling={{ width: "34rem" }}>
      <Alert variant="info" mb="1.5rem" alignIcon="flex-start">
        <Alert.Description>
          When the deal goes live, you will be able to create multiple deal
          invite links with custom carry and fees.
        </Alert.Description>
      </Alert>

      <Form
        onSubmit={onSubmit}
        fieldsToWatch={watchedFields}
        onFieldsChange={onFieldsChange}
        config={{
          defaultValues: initialValues.form,
        }}
      >
        <VStack gap="8" alignItems="flex-start">
          <Form.AmountField
            name={FormFields.DefaultCarry}
            placeholder="0"
            initialValue={initialValues.defaultCarry}
            rightSymbol="%"
            inputContainerStyle={{ width: inputMaxWidth }}
            invalidMessage="Please specify a percentage between 0 and 100%"
            label="Default carry*"
            subLabel="You can adjust carry for individual investors when the deal is live."
            validate={(val) =>
              validateAmount({
                amount: val,
                maxAllowed: 100,
                minAllowed: 0,
              })
            }
          />

          <Box>
            <RecipientsSummary
              missingRecipients={missingCarryRecipients}
              invalidPercentages={invalidPercents}
            />
            {carryRecipients.length ? (
              <CarryRecipients
                recipients={carryRecipients}
                defaultCarry={Number(formValues.defaultCarry)}
                disabled={!withCarry}
                onRemoveRecipient={onRemoveRecipient}
                onUpdatePercent={onAddPercent}
              />
            ) : null}
            <Dialog.Trigger>
              <Button
                variant="secondary"
                data-testid="add-lead-button"
                onClick={onOpenModal}
                mt="1"
              >
                <HStack>
                  <PlusCircleIcon height="18px" width="18px" color="white" />
                  <p> Create carry recipient </p>
                </HStack>
              </Button>
            </Dialog.Trigger>
          </Box>
          <Form.AmountField
            name={FormFields.SyndLeadsCommitment}
            placeholder="0"
            initialValue={String(initialValues.commitment)}
            leftSymbol={currencySign}
            inputStyle={{ width: inputMaxWidth }}
            invalidMessage="Please specify a commitment amount"
            validate={(val) =>
              validateAmount({
                amount: val,
                minAllowed: 0,
              })
            }
            label="Your commitment as syndicate lead*"
            subLabel="You can make your personal investment through the Odin platform once the deal is live"
          />
        </VStack>
        <StepSubmit isDisabled={invalidPercents} isLoading={isSubmitting} />
      </Form>
      <Dialog.Content>
        <AddRecipientModal
          onAddRecipient={onAddRecipient}
          recipients={carryRecipients}
        />
      </Dialog.Content>
    </Dialog>
  );
}
