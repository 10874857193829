import { BriefcaseIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import {
  BuildingStorefrontIcon,
  CheckCircleIcon,
  ClipboardDocumentIcon,
  CreditCardIcon,
  CurrencyPoundIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import { Link } from "components/interaction/Link";
import React, { ComponentPropsWithRef, ReactNode } from "react";

import { QuestionShape } from "./components/FAQAccordion/FAQAccordion";
import { CarryStep } from "./steps/Carry/CarryStep";
import { CompanyInfoStep } from "./steps/CompanyInfo/CompanyInfoStep";
import { DealTermsStep } from "./steps/DealTerms/DealTermsStep";
import { MemoMaterialsStep } from "./steps/MemoMaterials/MemoMaterialsStep";
import { PricingStep } from "./steps/Pricing/PricingStep";
import { ReviewSubmitStep } from "./steps/ReviewSubmit/ReviewSubmitStep";
import { SecondarySellerStep } from "./steps/SecondarySeller/SecondarySellerStep";
import { SelectSyndicateStep } from "./steps/SelectSyndicate/SelectSyndicateStep";

export enum StepUrls {
  CompanyInfo = "company",
  DealTerms = "terms",
  MemoMaterials = "materials",
  ReviewSubmit = "review",
  SelectSyndicate = "syndicate",
  SecondarySeller = "secondary_seller",
  CarryFees = "fees",
  Pricing = "pricing",
}

export interface StepShape {
  component: React.FC;
  title: string;
  id: StepUrls;
  next: StepUrls;
  prev: StepUrls;
  icon: (svgProps: ComponentPropsWithRef<"svg">) => ReactNode;
  faqs?: QuestionShape[];
}

export interface StepSpec {
  [key: string]: StepShape;
}

// ------------------ //
//   Shared Steps     //
// ----------------- //
export const getCompanyInfoStep = (
  type: "founder" | "primary" | "secondary"
) => {
  const primaryQ = {
    id: "1-1",
    title: "Does Odin communicate with the Investee company on my behalf?",
    answer: (
      <>
        Yes, to fulfil Odin’s statutory obligations, Odin will email the
        individual you provide to request they complete{" "}
        <Link
          href="https://airtable.com/appWiNGdQgVmJgLLt/shrx53512cTFYocRp"
          isExternal
        >
          this form.{" "}
        </Link>
        We also need the company’s ultimate owners to complete identity
        verification before you can wire funds from this deal. You can still
        raise funds before this is complete.
      </>
    ),
  };

  const canIChangeInfoLater = {
    id: "1-2",
    title: "Can I change this information later?",
    answer:
      "So long as your deal is in draft mode, you can make as many edits as you like. If you’d like to edit after submitting your deal for review, you’ll need to contact us.",
  };

  const companyInfoStep: StepSpec = {
    [StepUrls.CompanyInfo]: {
      component: CompanyInfoStep,
      title: type === "founder" ? "Company info" : "Investee company info",
      id: StepUrls.CompanyInfo,
      next: StepUrls.MemoMaterials,
      prev: StepUrls.CompanyInfo,
      icon: BuildingStorefrontIcon,
      faqs:
        type === "primary"
          ? [primaryQ, canIChangeInfoLater]
          : [canIChangeInfoLater],
    },
  };

  return companyInfoStep;
};

export const termsStep: StepSpec = {
  [StepUrls.DealTerms]: {
    component: DealTermsStep,
    title: "Deal terms",
    id: StepUrls.DealTerms,
    next: StepUrls.MemoMaterials,
    prev: StepUrls.CompanyInfo,
    icon: ClipboardDocumentIcon,
    faqs: [
      {
        id: "1-1",
        title: "What is proxy voting?",
        answer:
          "Proxy voting is where you delegate decision making for a group of investors to an individual to streamline investor consent matters.",
      },
    ],
  },
};

export const materialsStep: StepSpec = {
  [StepUrls.MemoMaterials]: {
    component: MemoMaterialsStep,
    title: "Memo & materials",
    id: StepUrls.MemoMaterials,
    next: StepUrls.ReviewSubmit,
    prev: StepUrls.DealTerms,
    icon: PencilIcon,
    faqs: [
      {
        id: "1-1",
        title: "Why do I need to upload the Investment Agreement?",
        answer:
          "When investors are invited to your live deal they have the opportunity to view legal documents associated with their potential investment.",
      },
      {
        id: "1-2",
        title: "What if I don’t have the final Investment Agreement?",
        answer:
          "We accept term sheets or draft documents. We recommend using Google Docs so the link to the folder/file does not change.",
      },
    ],
  },
};

export const reviewStep: StepSpec = {
  [StepUrls.ReviewSubmit]: {
    component: ReviewSubmitStep,
    title: "Review & submit",
    id: StepUrls.ReviewSubmit,
    next: StepUrls.ReviewSubmit,
    prev: StepUrls.MemoMaterials,
    icon: CheckCircleIcon,
    faqs: [
      {
        id: "1-1",
        title: "What happens next?",
        answer:
          "We’ll review the deal, and if everything looks good to go we’ll email you asking for confirmation to put the deal live. At this point you’re able to share it via link and begin collecting investments.",
      },
      {
        id: "1-2",
        title: "How quickly can I close a deal?",
        answer:
          "After you submit your deal for review, we’ll respond within 2 business days. In most cases, your deal will go live in that timeframe. Once the deal goes live, closing the deal is dependent on receiving funds from your investors. All capital must be received before the investment documents are signed and the funds are wired. Wire execution typically takes at least one business day’s lead time in order to account for bank security measures.",
      },
    ],
  },
};

// ------------------- //
//   Founder Steps     //
// ------------------ //
export const founderSteps = {
  steps: {
    ...getCompanyInfoStep("founder"),
    ...termsStep,
    ...materialsStep,
    ...reviewStep,
  },
  initialStep: StepUrls.CompanyInfo,
};

// ------------------------------ //
//   Syndicate Primary Steps     //
// ---------------------------- //
const syndicateStep: StepSpec = {
  [StepUrls.SelectSyndicate]: {
    component: SelectSyndicateStep,
    title: "Select a syndicate",
    id: StepUrls.SelectSyndicate,
    prev: StepUrls.SelectSyndicate,
    next: StepUrls.CompanyInfo,
    icon: BriefcaseIcon,
    faqs: [
      {
        id: "1-1",
        title: "What is a syndicate?",
        answer:
          "A syndicate is a group of investors that pool their capital to invest in deals. Setting up a syndicate lets you create deals on the Odin platform and invite others to invest on a deal-by-deal basis.",
      },
    ],
  },
};

const carryStep: StepSpec = {
  [StepUrls.CarryFees]: {
    component: CarryStep,
    title: "Carry & fees",
    id: StepUrls.CarryFees,
    prev: StepUrls.MemoMaterials,
    next: StepUrls.ReviewSubmit,
    icon: CreditCardIcon,
    faqs: [
      {
        id: "1-1",
        title: "What does pro-rata mean?",
        answer:
          "Pro-rata means that the investors pay a percentage of Odin's fee equal to their percentage of the total investment. For example, if the total round size is $100k and Odin's total fee is $3,950, If I invested $10k I'd pay $395 (10%).",
      },
    ],
  },
};

const syndicateMaterialStep = {
  [StepUrls.MemoMaterials]: {
    ...materialsStep[StepUrls.MemoMaterials],
    next: StepUrls.CarryFees,
  },
} as unknown as StepSpec;

const pricingStep: StepSpec = {
  [StepUrls.Pricing]: {
    component: PricingStep,
    title: "Pricing",
    id: StepUrls.Pricing,
    prev: StepUrls.CarryFees,
    next: StepUrls.ReviewSubmit,
    icon: CurrencyPoundIcon,
  },
};

export const syndicatePrimarySteps = {
  steps: {
    ...syndicateStep,
    ...getCompanyInfoStep("primary"),
    ...termsStep,
    ...syndicateMaterialStep,
    ...carryStep,
    ...pricingStep,
    ...reviewStep,
  },
  initialStep: StepUrls.SelectSyndicate,
};

// -------------------------------- //
//   Syndicate Secondary Steps     //
// ------------------------------ //
const secondarySellerStep: StepSpec = {
  [StepUrls.SecondarySeller]: {
    component: SecondarySellerStep,
    title: "Seller",
    id: StepUrls.SecondarySeller,
    prev: StepUrls.SelectSyndicate,
    next: StepUrls.CompanyInfo,
    icon: UserPlusIcon,
    faqs: [
      {
        id: "1-1",
        title: "Can I change this information later?",
        answer:
          "Until your deal goes live, you can make as many edits as you like. Once your deal has been published, you can edit some of the information, such as the memo & materials or the deal terms. To edit any other information, you'll need to contact us.",
      },
    ],
  },
};

export const syndicateSecondarySteps = {
  steps: {
    ...syndicateStep,
    ...secondarySellerStep,
    ...getCompanyInfoStep("secondary"),
    ...termsStep,
    ...syndicateMaterialStep,
    ...carryStep,
    ...pricingStep,
    ...reviewStep,
  },
  initialStep: StepUrls.SelectSyndicate,
};
