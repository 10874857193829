import { useContext } from "react";
import { CreateDealContext } from "screens/create-deal/context";
import { isSyndicateDraft } from "screens/create-deal/utils";

export function useProxyVotingField() {
  const { currentDraft } = useContext(CreateDealContext);

  const isFounderDeal = isSyndicateDraft(currentDraft.pricing_plan) === false;

  return {
    isFounderDeal,
  };
}
