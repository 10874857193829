import { graphql, useGraphqlMutation } from "@internal/gql/client";
import {
  Currency,
  InvestmentStatus,
  MonetaryValue,
  UploadDealDocumentInput,
} from "@internal/gql/generated/types";
import { formatCurrency } from "@internal/utils/currency";

import { GetInvestorsTypes } from "./components/Tabs/InvestorTab/useInvestorTab";

export function computeInvestorData(investor: GetInvestorsTypes["investor"]) {
  return {
    carriedInterest: `${investor.carry}%`,
    commitment: investor.currentCalculations.committed,
    email: investor.account.email,
    name: investor.account.fullName.trim() || investor.account.email,
    received: investor.received,
    totalAmount: computeTotalAmount({
      investor,
      status: investor.investmentStatus,
    }),
  };
}

interface ComputeTotalAmountArgs {
  status: InvestmentStatus;
  investor: GetInvestorsTypes["investor"];
}

export function computeTotalAmount({ investor }: ComputeTotalAmountArgs) {
  const defaultLabel = "-";

  const status = investor.investmentStatus;

  if (
    status === "COMMITMENT_REQUIRED" ||
    status === "TERMS_ACCEPTANCE_REQUIRED" ||
    status === "PAYMENT_REQUIRED" ||
    status === "TRANSFER_INITIATED"
  ) {
    return investor.currentCalculations.committed?.amount
      ? investor.currentCalculations.committed?.formatted
      : defaultLabel;
  }

  if (status === "PAYMENT_RECEIVED" || status === "COMPLETED") {
    return investor.received?.formatted || defaultLabel;
  }

  return defaultLabel;
}

export function renderAmount(value: MonetaryValue | undefined | null): string {
  if (!value) return "-";
  return value.amount === 0 ? "-" : value.formatted;
}

export const useFileUpload = (
  input: Omit<UploadDealDocumentInput, "document">
) => {
  const { mutateAsync } = useGraphqlMutation(uploadFileDoc);

  const handleFileUpload = async (document: File) => {
    return await mutateAsync({
      input: {
        ...input,
        document,
      },
    });
  };

  return { handleFileUpload };
};

const uploadFileDoc = graphql(`
  mutation UploadDealDocument($input: UploadDealDocumentInput!) {
    uploadDealDocument(input: $input) {
      id
      pitchDeckURL
      pitchDeckEmbedURL
      documents {
        __typename
        ... on DealLeadDocuments {
          defaultDealSheet
          dealSheetDocument
          syndicateTermsDocument
          investmentAgreementDocument
          platformAgreementDocument
        }
      }
    }
  }
`);

export const getDefaultMinCommitment = (currency: Currency): MonetaryValue => {
  const amount = 1000;
  return {
    __typename: "MonetaryValue",
    amount,
    currency: currency,
    formatted: formatCurrency({
      baseCurrency: currency,
      num: amount,
    }),
  };
};
