import { FieldRow } from "components/display/FieldRow";

import { useSecondarySellerSection } from "./useSecondarySellerSection";

export function SecondarySellerSection() {
  const section = useSecondarySellerSection();

  return (
    <>
      <FieldRow label="Seller name" value={section.sellerName} />
      <FieldRow label="Seller email" value={section.sellerEmail} />
    </>
  );
}
