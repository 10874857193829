import { Box } from "@@panda/jsx";
import { Form } from "components/forms/Form/Form";

import { FormFields, FormLabels } from "../../utils";
import { useSyndicateNameField } from "./useSyndicateNameField";

export function SyndicateNameField() {
  const { isVisible } = useSyndicateNameField();

  return isVisible ? (
    <Box mt="l">
      <Form.TextField
        name={FormFields.SyndicateName}
        placeholder="Enter syndicate name here"
        maxWidth="16rem"
        label={FormLabels.SyndicateName}
        subLabel="This is how you'd like to appear on the Odin platform"
        fieldOpts={{ required: "Please enter syndicate name" }}
      />
    </Box>
  ) : null;
}
