import { Box } from "@@panda/jsx";
import { InvestmentRound } from "@internal/rest/generated/schemas";
import { Form } from "components/forms/Form/Form";

import { inputMaxWidth } from "../../DealTermsStep";
import { FormFields, FormLabels } from "../../utils";

export function RoundField() {
  return (
    <Box w="full" style={{ maxWidth: inputMaxWidth }}>
      <Form.Combobox
        name={FormFields.Round}
        label={FormLabels.Round}
        placeholder="Type and select a round type"
        invalidErrorMessage="Please type and select a round type"
        fieldOpts={{ required: true }}
        options={[
          { value: InvestmentRound.pre_seed, label: "Pre-seed" },
          { value: InvestmentRound.seed, label: "Seed" },
          {
            value: InvestmentRound.bridge_to_series_a,
            label: "Bridge to Series A",
          },
          { value: InvestmentRound.series_a, label: "Series A" },
          {
            value: InvestmentRound.bridge_to_series_b,
            label: "Bridge to Series B",
          },
          { value: InvestmentRound.series_b, label: "Series B" },
          {
            value: InvestmentRound.bridge_to_series_c,
            label: "Bridge to Series C",
          },
          { value: InvestmentRound.series_c, label: "Series C" },
          {
            value: InvestmentRound.bridge_to_series_d,
            label: "Bridge to Series D+",
          },
          { value: InvestmentRound.series_d, label: "Series D+" },
        ]}
      />
    </Box>
  );
}
