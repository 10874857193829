import { Box, VStack } from "@@panda/jsx";
import { Form } from "components/forms/Form/Form";

import { StepSubmit } from "../../components/StepSubmit/StepSubmit";
import { AllocationField } from "./components/AllocationField/AllocationField";
import { CurrencyField } from "./components/CurrencyField/CurrencyField";
import { CustomTermsField } from "./components/CustomTermsField/CustomTermsField";
import { EquityFields } from "./components/EquityFields/EquityFields";
import { InvestmentTypeField } from "./components/InvestmentTypeField/InvestmentTypeField";
import { MinCommitmentField } from "./components/MinCommitmentField/MinCommitmentField";
import { NonEquityFields } from "./components/NonEquityFields/NonEquityFields";
import { ProxyVotingField } from "./components/ProxyVotingField/ProxyVotingField";
import { RoundField } from "./components/RoundField/RoundField";
import { SEISFields } from "./components/SEISFields/SEISFields";
import { TargetCloseField } from "./components/TargetCloseField/TargetCloseField";
import { TotalRoundField } from "./components/TotalRoundField/TotalRoundField";
import { useDealTermsStep } from "./useDealTermsStep";

export const formLabelStyleProps = {
  fontSize: "sm",
  fontWeight: "semibold",
  m: "0",
};

export const inputMaxWidth = "16rem";

export function DealTermsStep() {
  const {
    handleSubmit,
    handleSaveCustomTerms,
    isSubmitting,
    defaultValues,
    isSecondaryDeal,
    isFounderDeal,
  } = useDealTermsStep();

  return (
    <Box data-testid="deal-terms-step">
      {isSecondaryDeal ? (
        <Form
          onSubmit={handleSubmit}
          config={{
            defaultValues,
          }}
        >
          <VStack gap="8" alignItems="flex-start">
            <CurrencyField />
            <InvestmentTypeField />
            <EquityFields />
            <MinCommitmentField />
            <TargetCloseField />
            <ProxyVotingField />
            <AllocationField />
            <CustomTermsField onSaveCustomTerms={handleSaveCustomTerms} />
          </VStack>
          <StepSubmit isLoading={isSubmitting} />
        </Form>
      ) : isFounderDeal ? (
        <Form
          onSubmit={handleSubmit}
          config={{
            defaultValues,
          }}
        >
          {/* Same fields as primary but without custom terms */}
          <VStack gap="8" alignItems="flex-start">
            <CurrencyField />
            <SEISFields />
            <RoundField />
            <InvestmentTypeField />
            <EquityFields />
            <NonEquityFields />
            <TotalRoundField />
            <AllocationField />
            <MinCommitmentField />
            <TargetCloseField />
            <ProxyVotingField />
          </VStack>
          <StepSubmit isLoading={isSubmitting} />
        </Form>
      ) : (
        <Form
          onSubmit={handleSubmit}
          config={{
            defaultValues,
          }}
        >
          <VStack gap="8" alignItems="flex-start">
            <CurrencyField />
            <SEISFields />
            <RoundField />
            <InvestmentTypeField />
            <EquityFields />
            <NonEquityFields />
            <TotalRoundField />
            <AllocationField />
            <MinCommitmentField />
            <TargetCloseField />
            <ProxyVotingField />
            <CustomTermsField onSaveCustomTerms={handleSaveCustomTerms} />
          </VStack>
          <StepSubmit isLoading={isSubmitting} />
        </Form>
      )}
    </Box>
  );
}
