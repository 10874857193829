import { BaseCurrency } from "@internal/rest/generated/schemas";
import { Form } from "components/forms/Form/Form";

import { inputMaxWidth } from "../../DealTermsStep";
import { FormFields, FormLabels } from "../../utils";

export function CurrencyField() {
  return (
    <Form.Select
      name={FormFields.Currency}
      label={FormLabels.Currency}
      subLabel="This is the currency that the investors will be asked to transfer."
      selectContainerStyle={{ maxWidth: inputMaxWidth }}
      fieldOpts={{ required: true }}
    >
      <option value={BaseCurrency.gbp} key={BaseCurrency.gbp}>
        GBP
      </option>

      <option value={BaseCurrency.usd} key={BaseCurrency.usd}>
        USD
      </option>

      <option value={BaseCurrency.eur} key={BaseCurrency.eur}>
        EUR
      </option>
    </Form.Select>
  );
}
