import {
  InvestmentType,
  TransactionType,
} from "@internal/rest/generated/schemas";
import { formatCurrency } from "@internal/utils/currency";
import { formatInvestmentTypeREST } from "@internal/utils/deals";
import { useContext } from "react";
import { CreateDealContext } from "screens/create-deal/context";
import { isFounderDraft } from "screens/create-deal/utils";
import {
  formatInvestmentRound,
  formatProxyVotingValue,
  formatShareClassValue,
  formatValuationCapType,
} from "utility/deals";

import { computeSharePriceDecimals } from "../../utils";
import { computeTaxReliefText } from "./utils";

export function useTermsSection() {
  const { currentDraft } = useContext(CreateDealContext);
  const { terms, target_closing_at } = currentDraft;
  const baseCurrency = terms?.base_currency || "gbp";
  const isEquity = terms?.investment_type === InvestmentType.equity;
  const isSecondary = terms?.transaction_type === TransactionType.secondary;
  const isFounder = isFounderDraft(currentDraft.pricing_plan);

  return {
    currencyRaw: baseCurrency,
    isEquity,
    isSecondary,
    baseCurrency,
    isFounder,
    taxRelief: computeTaxReliefText({
      eis: Boolean(terms?.is_eis_eligible),
      seis: Boolean(terms?.is_seis_eligible),
    }),
    isEisEligible: terms?.is_eis_eligible,
    round: formatInvestmentRound(terms?.round),
    investmentInstrument: formatInvestmentTypeREST(terms?.investment_type),
    shareClass: formatShareClassValue(terms?.equity_share_class),
    sharePrice:
      terms?.equity_share_price &&
      formatCurrency({
        num: terms?.equity_share_price || 0,
        baseCurrency,
        minimumFractionDigits: computeSharePriceDecimals(
          terms?.equity_share_price
        ),
      }),
    customTerms: terms?.custom_terms,
    moneyValuation: terms?.pre_money_valuation,
    valuationCap: terms?.valuation_cap,
    totalRoundSize: terms?.total_round_size,
    minCommitment: terms?.minimum_commitment,
    discount: terms?.discount ? `${terms?.discount}%` : undefined,
    valuationCapType: formatValuationCapType(terms?.valuation_cap_type),
    proxyVoting: formatProxyVotingValue(terms?.proxy_voting_rights),
    allocation: terms?.allocation,
    targetCloseDate: target_closing_at ?? "",
  };
}
