import { Box, VStack } from "@@panda/jsx";
import { Alert } from "components/feedback/Alert";
import { StepUrls } from "screens/create-deal/steps";

import { CarrySection } from "./components/CarrySection/CarrySection";
import { CompanySection } from "./components/CompanySection/CompanySection";
import { HeroSection } from "./components/HeroSection/HeroSection";
import { MaterialsSection } from "./components/MaterialsSection/MaterialsSection";
import { PricingSection } from "./components/PricingSection/PricingSection";
import { ReviewSectionHeading } from "./components/ReviewSectionHeading";
import { SecondarySellerSection } from "./components/SecondarySellerSection/SecondarySellerSection";
import { SubmitTermsModal } from "./components/SubmitTermsModal/SubmitTermsModal";
import { SyndicateSection } from "./components/SyndicateSection/SyndicateSection";
import { TermsSection } from "./components/TermsSection/TermsSection";
import { useReviewSubmitStep } from "./useReviewSubmitStep";

export function ReviewSubmitStep() {
  const {
    data,
    onEditDeal,
    onSubmitDeal,
    isSubmitting,
    isEditDisabled,
    isSubmitEnabled,
    isSyndicateDeal,
    isSecondaryDeal,
    isOffPlatformDeal,
  } = useReviewSubmitStep();

  return (
    <Box>
      <HeroSection companyLogo={data.companyLogo || ""} />

      {isSyndicateDeal ? (
        <VStack
          alignItems="flex-start"
          gap="0.75rem"
          mb="3rem"
          data-testid="syndicate-section"
        >
          <ReviewSectionHeading
            title="Syndicate details"
            {...(isEditDisabled
              ? null
              : { onEditClick: () => onEditDeal(StepUrls.SelectSyndicate) })}
          />
          <SyndicateSection />
        </VStack>
      ) : null}

      {isSecondaryDeal ? (
        <VStack
          alignItems="flex-start"
          gap="0.75rem"
          mb="3rem"
          data-testid="secondary-section"
        >
          <ReviewSectionHeading
            title="Seller"
            {...(isEditDisabled
              ? null
              : { onEditClick: () => onEditDeal(StepUrls.SecondarySeller) })}
          />
          <SecondarySellerSection />
        </VStack>
      ) : null}

      <VStack
        alignItems="flex-start"
        gap="0.75rem"
        mb="3rem"
        data-testid="company-section"
      >
        <ReviewSectionHeading
          title="Company info"
          {...(isEditDisabled
            ? null
            : { onEditClick: () => onEditDeal(StepUrls.CompanyInfo) })}
        />
        <CompanySection companyLogo={data.companyLogo} />
      </VStack>

      <VStack
        alignItems="flex-start"
        gap="0.75rem"
        mb="xl"
        data-testid="terms-section"
      >
        <ReviewSectionHeading
          title="Deal terms"
          {...(isEditDisabled
            ? null
            : { onEditClick: () => onEditDeal(StepUrls.DealTerms) })}
        />
        <TermsSection />
      </VStack>

      <VStack
        alignItems="flex-start"
        gap="0.75rem"
        mb="3rem"
        data-testid="materials-section"
      >
        <ReviewSectionHeading
          title="Memo &amp; materials"
          {...(isEditDisabled
            ? null
            : { onEditClick: () => onEditDeal(StepUrls.MemoMaterials) })}
        />
        <MaterialsSection />
      </VStack>

      {isSyndicateDeal ? (
        <VStack
          alignItems="flex-start"
          gap="0.75rem"
          mb="3rem"
          data-testid="carry-section"
        >
          <ReviewSectionHeading
            title="Carry & fees"
            {...(isEditDisabled
              ? null
              : { onEditClick: () => onEditDeal(StepUrls.CarryFees) })}
          />
          <CarrySection />
        </VStack>
      ) : null}

      <VStack
        alignItems="flex-start"
        gap="0.75rem"
        mb="3rem"
        data-testid="platform-fee"
      >
        <ReviewSectionHeading
          title="Platform fee"
          {...(isEditDisabled
            ? null
            : {
                onEditClick: isSyndicateDeal
                  ? () => onEditDeal(StepUrls.Pricing)
                  : null, // founder deals don't have pricing step
              })}
        />
        <PricingSection />
      </VStack>

      {isSubmitEnabled ? (
        <>
          <SubmitTermsModal
            onSubmit={onSubmitDeal}
            isSubmitting={isSubmitting}
          />
        </>
      ) : null}

      {isOffPlatformDeal ? (
        <>
          <Alert variant="info">
            Please get in touch with us at sales@joinodin.com for creating deals
            under the Institutional plan.
          </Alert>
        </>
      ) : null}
    </Box>
  );
}
