import {
  dealsGetDraft,
  getDealsGetDraftQueryKey,
} from "@internal/rest/generated/queries/drafts";
import { Draft } from "@internal/rest/generated/schemas";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useFileUpload } from "screens/deal-admin/utils";
import { isOdinHostedUrl } from "utility/url";

import { FormValues } from "../../utils";

export const useInvestmentAgreementField = () => {
  const router = useRouter();
  const draftId = (router.query["deal"] || router.query["id"]) as string;
  const formCtx = useFormContext<FormValues>();
  const queryClient = useQueryClient();

  const invAgValue = formCtx.watch("investment_agreement");
  const hasUploadedFile = isOdinHostedUrl(invAgValue);

  const [mode, setMode] = useState<"text" | "file">(() => {
    if (invAgValue) return hasUploadedFile ? "file" : "text";
    return "file";
  });

  const { handleFileUpload } = useFileUpload({
    dealID: draftId,
    documentType: "INVESTMENT_AGREEMENT",
  });

  const resetField = async () => {
    formCtx.setValue("investment_agreement", "");
    await formCtx.trigger("investment_agreement");
  };

  const handleFileDrop = async (f: File) => {
    await handleFileUpload(f);
    const d = await dealsGetDraft(draftId);
    await queryClient.setQueryData(getDealsGetDraftQueryKey(draftId), d); // 1️⃣ important for latest data after upload to be set into cache
    formCtx.setValue(
      "investment_agreement",
      d?.materials?.investment_agreement ?? ""
    );
    await formCtx.trigger("investment_agreement");
  };

  const handleFileTabClick = async () => {
    const draftCache = queryClient.getQueryData<Draft>(
      getDealsGetDraftQueryKey(draftId)
    );
    const agreement = draftCache?.materials?.investment_agreement ?? "";

    if (isOdinHostedUrl(agreement)) {
      // 2️⃣ because its used here to check if the latest value for pitch deck was uploaded so that file state can be restored
      formCtx.setValue("investment_agreement", agreement);
      await formCtx.trigger("investment_agreement");
    } else {
      await resetField();
    }

    setMode("file");
  };

  const handleLinkTabClick = async () => {
    const draftCache = queryClient.getQueryData<Draft>(
      getDealsGetDraftQueryKey(draftId)
    );
    const agreement = draftCache?.materials?.investment_agreement ?? "";

    if (isOdinHostedUrl(agreement)) {
      await resetField();
    } else {
      formCtx.setValue("investment_agreement", agreement);
      await formCtx.trigger("investment_agreement");
    }

    setMode("text");
  };

  return {
    mode,
    formCtx,
    handleFileTabClick,
    handleLinkTabClick,
    handleFileDrop,
    resetField,
  };
};
