import {
  Draft,
  PricePlanName,
  TransactionType,
} from "@internal/rest/generated/schemas";

import {
  StepUrls,
  founderSteps,
  syndicatePrimarySteps,
  syndicateSecondarySteps,
} from "./steps";

type draftKey =
  | "company"
  | "terms"
  | "materials"
  | "syndicate"
  | "fees"
  | "secondary_seller";

export function computeCompletedSteps(
  draft: Draft,
  stepOrder: string[],
  isPricingComplete: boolean
) {
  return (
    Object.keys(draft)
      .map((rawStepKey) => {
        const stepKey = rawStepKey as draftKey;
        const step = draft[stepKey];
        return step?.complete ? stepKey : "";
      })
      //@ts-expect-error hacking in Pricing which is not a backend step or part of draft object
      .concat(isPricingComplete ? StepUrls.Pricing : null)
      .filter(Boolean)
      .filter((step) => stepOrder.includes(step))
      .sort((a, b) => {
        const aItem = a as StepUrls;
        const bItem = b as StepUrls;
        const aIndex = stepOrder.indexOf(aItem);
        const bIndex = stepOrder.indexOf(bItem);
        return aIndex > bIndex ? 1 : aIndex < bIndex ? -1 : -1;
      })
  );
}

interface ValidateMinimumAmountArgs {
  amount: string | undefined;
  minAllowed?: number;
  maxAllowed?: number;
  required?: boolean;
}

export function validateAmount({
  amount,
  minAllowed = 1000,
  maxAllowed = undefined,
  required = true,
}: ValidateMinimumAmountArgs) {
  if (!amount && required) return false;
  if (!amount && !required) return true;

  const actualAmount = Number(amount);

  if (maxAllowed)
    return actualAmount >= minAllowed && actualAmount <= maxAllowed;

  return actualAmount >= minAllowed;
}

export function handleInitialAmount(amount: number | undefined) {
  return amount !== undefined ? String(amount) : "";
}

export function isSyndicateDraft(pp: PricePlanName | undefined): boolean {
  if (
    pp === PricePlanName.founder_2023 ||
    pp === PricePlanName.founder_2023_07 ||
    pp === PricePlanName.founder_2023_09 ||
    pp === PricePlanName.founder_2024
  ) {
    return false;
  }

  return true;
}

export function isFounderDraft(pp: PricePlanName | undefined): boolean {
  return !isSyndicateDraft(pp);
}

export function isSecondarySyndicateDeal(draft: Draft | undefined) {
  if (!draft) return false;
  if (!isSyndicateDraft(draft?.pricing_plan)) return false;

  return draft.terms?.transaction_type === TransactionType.secondary;
}

export function isPrimarySyndicateDeal(draft: Draft | undefined) {
  if (!draft) return false;
  if (!isSyndicateDraft(draft?.pricing_plan)) return false;

  return draft.terms?.transaction_type !== TransactionType.secondary;
}

export function computeRawSteps(draft: Draft | undefined) {
  if (!draft) return founderSteps;

  if (isPrimarySyndicateDeal(draft)) return syndicatePrimarySteps;
  if (isSecondarySyndicateDeal(draft)) return syndicateSecondarySteps;

  return founderSteps;
}
