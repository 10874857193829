import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CreateDealContext } from "screens/create-deal/context";
import { toast } from "services/toast";
import { delay } from "utility/async";

export function useStepSubmit() {
  const draftContext = useContext(CreateDealContext);
  const formContext = useFormContext();
  const hasUpdated = formContext.formState.isSubmitSuccessful;
  const inReview = draftContext.currentDraft.status === "review";

  useEffect(() => {
    if (inReview && hasUpdated) {
      void showUpdateToast();
    }
  }, [inReview, hasUpdated]);

  return {
    inReview,
  };
}

/**
 * @note
 * Do not fire the the toast straight-away. The user is first redirect to the
 * Review Step when updating a draft in review. Wait until the redirect has
 * happened before showing the toast.
 */
async function showUpdateToast() {
  await delay(500);
  toast.show({ title: "Draft updated", status: "success" });
}
