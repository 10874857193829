import {
  BaseCurrency,
  InvestmentType,
  ValuationCapType,
} from "@internal/rest/generated/schemas";
import { getCurrencySign } from "@internal/utils/currency";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { FormFields } from "../../utils";

export function useNonEquityFields() {
  const { clearErrors, watch } = useFormContext();

  const valuationCapType = watch(FormFields.ValuationCapType);
  const investmentType = watch(FormFields.Instrument);
  const currency = watch(FormFields.Currency);

  useEffect(() => {
    if (valuationCapType === ValuationCapType.none) {
      clearErrors(FormFields.ValuationCap);
    }
  }, [valuationCapType]);

  const isReady = investmentType !== "undefined";
  const isVisible = investmentType !== InvestmentType.equity && isReady;

  return {
    isVisible,
    valuationCapType,
    currencySign: getCurrencySign(currency || BaseCurrency.gbp),
  };
}
