import { useContext } from "react";
import { CreateDealContext } from "screens/create-deal/context";

interface Args {
  companyLogo: string;
}

export function useCompanySection(args: Args) {
  const { currentDraft } = useContext(CreateDealContext);
  const { company } = currentDraft;

  return {
    companyName: company?.trading_name as string,
    companyLegalName: company?.legal_name as string,
    companyUrl: company?.company_url as string,
    companyAddress: {
      firstLine: company?.address_line1 as string,
      secondLine: company?.address_line2 as string,
      city: company?.city as string,
      state: company?.state as string,
      postCode: company?.zip_code as string,
      country: company?.country as string,
    },
    contactName: company?.contact_first_name as string,
    contactEmail: company?.contact_email as string,
    registrationNum: company?.registration_number as string,
    companyLogo: args.companyLogo,
  };
}
