import { useWatch } from "react-hook-form";

import { CREATE_NEW_SYNDICATE_OPTION, FormFields } from "../../utils";

export function useSyndicateNameField() {
  const chosenSyndicate = useWatch({ name: FormFields.Syndicate });
  const ready = typeof chosenSyndicate !== "undefined";
  const isNewSyndicate = chosenSyndicate === CREATE_NEW_SYNDICATE_OPTION;

  return {
    isVisible: ready && isNewSyndicate,
  };
}
