import { Form } from "components/forms/Form/Form";
import { validateAmount } from "screens/create-deal/utils";

import { inputMaxWidth } from "../../DealTermsStep";
import { FormFields, FormLabels } from "../../utils";
import { useAllocationField } from "./useAllocationField";

export function AllocationField() {
  const { totalRound, currencySign, isSecondary } = useAllocationField();

  return (
    <>
      <Form.AmountField
        label={FormLabels.Allocation}
        subLabel="How much capital you intend to raise into your SPV"
        name={FormFields.Allocation}
        placeholder="100,000"
        leftSymbol={currencySign}
        invalidMessage={`Please specify an allocation of at least ${currencySign}1,000 and lower than the total round size`}
        testId="allocation"
        inputStyle={{ width: inputMaxWidth }}
        validate={(val) => {
          if (!val) return false;
          if (!isSecondary && Number(val) > totalRound) return false;
          return validateAmount({
            amount: val,
          });
        }}
      />
    </>
  );
}
