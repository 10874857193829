import {
  getDealsGetDraftQueryKey,
  useDealsSetDraftLogo,
  useDealsUpdateDraft,
} from "@internal/rest/generated/queries/drafts";
import { Country } from "@internal/rest/generated/schemas";
import { useQueryClient } from "@tanstack/react-query";
import { parseStoredCountry } from "components/forms/Form/FormCountrySelect/utils";
import { ImgArgs } from "components/interaction/AvatarEditor/AvatarEditor";
import { useContext, useEffect, useState } from "react";
import { isSyndicateDraft } from "screens/create-deal/utils";
import { useAuth } from "services/auth/AuthProvider";
import { toast } from "services/toast";
import { handleResourceEffect } from "utility/async";
import { useBooleanToggle } from "utility/hooks/useBooleanToggle";

import { CreateDealContext } from "../../context";
import { CompanyInfoSchemaType } from "./utils";

export function useCompanyInfoStep() {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const createDealContext = useContext(CreateDealContext);

  const [isSubmitting, toggleSubmitting] = useBooleanToggle();
  const [avatar, setAvatar] = useState<null | string>(null);

  const savedCountry = createDealContext.currentDraft.company?.country ?? "";
  const savedLogo = createDealContext.currentDraft.company?.logo_url ?? "";
  const isFounderDeal =
    isSyndicateDraft(createDealContext.currentDraft.pricing_plan) === false;

  const { mutateAsync: addLogo } = useDealsSetDraftLogo();
  const { mutateAsync: updateDraft } = useDealsUpdateDraft();

  useEffect(() => {
    if (!avatar && savedLogo) {
      setAvatar(savedLogo);
    }
  }, [savedLogo]);

  async function onSubmit(values: CompanyInfoSchemaType) {
    // don't allow to submit the form without an avatar...
    if (!avatar) {
      if (typeof window !== "undefined") window.scrollTo({ top: 0 });
      return;
    }

    toggleSubmitting();

    await handleResourceEffect({
      async action() {
        await updateDraft({
          dealDraftId: createDealContext.draftId,
          data: {
            company: {
              ...values,
              country: values.country as Country,
            },
          },
        });

        await queryClient.resetQueries({
          queryKey: getDealsGetDraftQueryKey(createDealContext.draftId),
        });

        createDealContext.onNextStep();
      },
      error: {
        title: "An unexpected draft problem occurred",
      },
      cleanup() {
        toggleSubmitting();
      },
    });
  }

  async function onSaveAvatar({ img, url }: ImgArgs) {
    try {
      await addLogo({
        dealDraftId: createDealContext.draftId,
        data: img,
      });

      await queryClient.invalidateQueries({
        queryKey: getDealsGetDraftQueryKey(createDealContext.draftId),
      });

      setAvatar(url);
    } catch (e: unknown) {
      const err = e as Error;
      toast.show({
        status: "error",
        title: "An unexpected draft problem ocurred",
        desc: `Something went wrong while uploading your company logo. In case the error persists contact us at hello@joinodin.com`,
      });

      // bubble-up the error so the Avatar Editor is aware of it...
      throw err;
    }
  }

  const initialValues: Partial<CompanyInfoSchemaType> = {
    ...createDealContext.currentDraft.company,
    ...(isFounderDeal
      ? {
          contact_email: auth.account?.email ?? "",
          contact_first_name: auth.account?.first_name ?? "",
        }
      : {}),
    country: parseStoredCountry(savedCountry),
  };

  return {
    avatar,
    onSaveAvatar,
    onSubmit,
    isSubmitting,
    initialValues,
  };
}
