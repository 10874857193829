import { useContext } from "react";
import { CreateDealContext } from "screens/create-deal/context";
import { isFounderDraft } from "screens/create-deal/utils";
import { formatOdinPlatformFeePayee } from "utility/deals";

export function usePlatformAndAdditionalFees() {
  const { currentDraft } = useContext(CreateDealContext);
  const isFounderDeal = isFounderDraft(currentDraft.pricing_plan);

  return {
    selectedPricePlan: currentDraft.pricing_plan,
    currency: currentDraft.terms?.base_currency,
    isFounderDeal,
    odinPlatformFeePayee: currentDraft.fees?.platform_fee_covered_by
      ? formatOdinPlatformFeePayee(currentDraft.fees.platform_fee_covered_by)
      : "–",
  };
}
