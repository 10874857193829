import { PlatformFeeType } from "@internal/rest/generated/schemas";
import { GetFormLabels } from "@internal/utils/types";
import { createNameAndLabels } from "utility/forms";
import { z } from "zod";

export const schema = z.object({
  platformFeeCovered: z.nativeEnum(PlatformFeeType),
});

export const { getNameAndLabel } = createNameAndLabels(schema, {
  platformFeeCovered: "Who will pay Odin’s platform fee?*",
});

export type FormValues = z.infer<typeof schema>;
export type FormLabels = GetFormLabels<typeof getNameAndLabel>;
