import { ValuationCapType } from "@internal/rest/generated/schemas";
import { Form } from "components/forms/Form/Form";
import { validateAmount } from "screens/create-deal/utils";

import { inputMaxWidth } from "../../DealTermsStep";
import { FormFields, FormLabels } from "../../utils";
import { useNonEquityFields } from "./useNonEquityFields";

export function NonEquityFields() {
  const { isVisible, valuationCapType, currencySign } = useNonEquityFields();

  return isVisible ? (
    <>
      <Form.Select
        name={FormFields.ValuationCapType}
        label={FormLabels.ValuationCapType}
        fieldOpts={{ required: true }}
        selectContainerStyle={{ maxWidth: inputMaxWidth }}
      >
        <option value={ValuationCapType.none}>None</option>
        <option value={ValuationCapType.pre}>Pre-money</option>
        <option value={ValuationCapType.post}>Post-money</option>
      </Form.Select>

      {valuationCapType !== ValuationCapType.none ? (
        <Form.AmountField
          name={FormFields.ValuationCap}
          label={FormLabels.ValuationCap}
          placeholder="1,000,000"
          leftSymbol={currencySign}
          invalidMessage={`Please specify a valuation cap`}
          inputStyle={{ width: inputMaxWidth }}
          validate={(val) =>
            validateAmount({
              amount: val,
              minAllowed: 1,
              required: true,
            })
          }
        />
      ) : null}

      <Form.AmountField
        name={FormFields.Discount}
        label={FormLabels.Discount}
        placeholder="0"
        rightSymbol="%"
        invalidMessage="If specified, discount should be a percentage between 0 and 100%"
        inputContainerStyle={{ width: inputMaxWidth }}
        validate={(val) =>
          validateAmount({
            amount: val,
            maxAllowed: 100,
            minAllowed: 0,
            required: false,
          })
        }
      />
    </>
  ) : null;
}
