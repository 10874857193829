import {
  getDealsGetDraftQueryKey,
  useDealsUpdateDraft,
} from "@internal/rest/generated/queries/drafts";
import { DraftSecondarySeller } from "@internal/rest/generated/schemas";
import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { Flags, useFeatureFlag } from "services/feature-flags";
import { handleResourceEffect } from "utility/async";
import { useBooleanToggle } from "utility/hooks/useBooleanToggle";

import { CreateDealContext } from "../../context";
import { getNameAndLabel } from "./utils";

export function useSecondarySellerStep() {
  const queryClient = useQueryClient();
  const createDealContext = useContext(CreateDealContext);
  const instantDealsFF = useFeatureFlag(Flags.INSTANT_DEALS);

  const [isSubmitting, toggleSubmitting] = useBooleanToggle();
  const { mutateAsync: updateDraft } = useDealsUpdateDraft();

  async function onSubmit(values: DraftSecondarySeller) {
    toggleSubmitting();

    await handleResourceEffect({
      async action() {
        await updateDraft({
          dealDraftId: createDealContext.draftId,
          data: {
            secondary_seller: {
              name: values.name ?? "",
              email: values.email ?? "",
            },
          },
        });

        await queryClient.resetQueries({
          queryKey: getDealsGetDraftQueryKey(createDealContext.draftId),
          exact: true,
        });

        createDealContext.onNextStep();
      },
      error: {
        title: "An unexpected draft problem occurred",
      },
      cleanup() {
        toggleSubmitting();
      },
    });
  }

  return {
    initialValues: {
      form: {
        [getNameAndLabel("name").name]:
          createDealContext.currentDraft.secondary_seller?.name,
        [getNameAndLabel("email").name]:
          createDealContext.currentDraft.secondary_seller?.email,
      },
    },
    onSubmit,
    isSubmitting,
    instantDealsFF,
  };
}
