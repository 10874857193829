import { styled } from "@@panda/jsx";
import { formatCurrency, getCurrencySign } from "@internal/utils/currency";
import { FieldRow } from "components/display/FieldRow";
import { Typography } from "components/display/Typography/Typography";
import { RichText } from "components/interaction/RichText";
import { Button } from "components/main/Button";
import { Dialog } from "components/main/Dialog";
import { formatIsoDate } from "utility/dates";

import { useTermsSection } from "./useTermsSection";

export function TermsSection() {
  const section = useTermsSection();

  const formatValue = (val: number) =>
    formatCurrency({
      num: val,
      minimumFractionDigits: 2,
      baseCurrency: section.baseCurrency,
    });

  return (
    <>
      <FieldRow
        label="Base currency"
        testId="currency-row"
        value={`${section.baseCurrency.toUpperCase()} (${getCurrencySign(
          section.baseCurrency
        )})`}
      />

      {!section.isSecondary ? (
        <FieldRow
          label="Tax relief"
          testId="tax-relief-row"
          value={section.taxRelief}
        />
      ) : null}

      <FieldRow
        label="Investment instrument"
        testId="investment-row"
        value={section.investmentInstrument}
      />

      {section.isSecondary ? (
        <FieldRow
          label="Transaction type"
          testId="transaction-row"
          value={<styled.p textTransform="capitalize">Secondary</styled.p>}
        />
      ) : (
        <FieldRow label="Round" testId="round-row" value={section.round} />
      )}

      {section.shareClass && (section.isEquity || section.isSecondary) ? (
        <FieldRow
          label="Share class"
          testId="share-class-row"
          value={
            <styled.p textTransform="capitalize">{section.shareClass}</styled.p>
          }
        />
      ) : null}

      {section.sharePrice && (section.isEquity || section.isSecondary) ? (
        <FieldRow
          label="Share price"
          testId="share-price-row"
          value={section.sharePrice}
        />
      ) : null}

      {section.moneyValuation && section.isEquity ? (
        <FieldRow
          label="Pre-money valuation"
          testId="valuation-row"
          value={formatValue(section.moneyValuation)}
        />
      ) : null}

      {section.discount && !section.isEquity ? (
        <FieldRow
          label="Discount"
          testId="discount-row"
          value={section.discount}
        />
      ) : null}

      {section.valuationCapType && !section.isEquity ? (
        <FieldRow
          label="Valuation cap type"
          testId="valuation-cap-type-row"
          value={section.valuationCapType}
        />
      ) : null}

      {section.valuationCap && !section.isEquity ? (
        <FieldRow
          label="Valuation cap"
          testId="valuation-cap-row"
          value={formatValue(section.valuationCap)}
        />
      ) : null}

      {section.totalRoundSize && !section.isSecondary ? (
        <FieldRow
          label="Total round size"
          testId="total-round-row"
          value={formatValue(section.totalRoundSize)}
        />
      ) : null}

      {section.allocation && !section.isSecondary ? (
        <FieldRow
          label="SPV Allocation"
          testId="allocation-row"
          value={formatValue(section.allocation)}
        />
      ) : null}

      {section.minCommitment ? (
        <FieldRow
          label="Minimum commitment"
          testId="min-commitment-row"
          value={formatValue(section.minCommitment)}
        />
      ) : null}

      {section.targetCloseDate ? (
        <FieldRow
          label="Target close date"
          testId="target-close-date-row"
          value={formatIsoDate(section.targetCloseDate)}
        />
      ) : null}

      <FieldRow
        label="Who handles voting?"
        testId="proxy-voting-row"
        value={section.proxyVoting}
      />

      {!section.isFounder ? (
        section.customTerms ? (
          <Dialog title="Custom terms">
            <FieldRow
              label={
                <Typography variant="body" color="grey.gunsmoke" minW="150px">
                  Custom terms
                </Typography>
              }
              testId="custom-terms-row"
              value={
                <Dialog.Trigger>
                  <Button
                    variant="secondary"
                    mb="2"
                    data-testid="deck-embed-preview-button"
                  >
                    Preview
                  </Button>
                </Dialog.Trigger>
              }
            />
            <Dialog.Content>
              <RichText type="viewer" initialContent={section.customTerms} />
            </Dialog.Content>
          </Dialog>
        ) : (
          <FieldRow
            label={
              <Typography variant="body" color="grey.gunsmoke" minW="150px">
                Custom terms
              </Typography>
            }
            testId="custom-terms-row"
            value="N/A"
          />
        )
      ) : null}
    </>
  );
}
