import { BaseCurrency, InvestmentType } from "@internal/rest/generated/schemas";
import { Form } from "components/forms/Form/Form";

import { inputMaxWidth } from "../../DealTermsStep";
import { FormFields, FormLabels } from "../../utils";
import { useInvestmentTypeField } from "./useInvestmentTypeField";

export function InvestmentTypeField() {
  const { currency, isReady } = useInvestmentTypeField();

  return isReady ? (
    <Form.Select
      name={FormFields.Instrument}
      label={FormLabels.Instrument}
      invalidErrorMessage="Please select an investment instrument"
      fieldOpts={{ required: true }}
      selectContainerStyle={{ maxWidth: inputMaxWidth }}
    >
      <option value={InvestmentType.equity}>Equity</option>
      <option value={InvestmentType.convertible}>Convertible loan note</option>
      {currency === BaseCurrency.gbp ? (
        <option value={InvestmentType.seedfast}>ASA</option>
      ) : null}
      <option value={InvestmentType.safe}>SAFE</option>
    </Form.Select>
  ) : null;
}
