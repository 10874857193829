import { Form } from "components/forms/Form/Form";
import { validateAmount } from "screens/create-deal/utils";

import { inputMaxWidth } from "../../DealTermsStep";
import { FormFields, FormLabels } from "../../utils";
import { useTotalRoundField } from "./useTotalRoundField";

export function TotalRoundField() {
  const { currencySign } = useTotalRoundField();

  return (
    <Form.AmountField
      label={FormLabels.TotalRound}
      subLabel="How much are you raising in total in this round (not just via your Odin SPV)"
      name={FormFields.TotalRound}
      placeholder="5,000,000"
      leftSymbol={currencySign}
      invalidMessage={`Please specify a total round size of at least ${currencySign}1,000`}
      inputStyle={{ width: inputMaxWidth }}
      validate={(val) =>
        validateAmount({
          amount: val,
        })
      }
    />
  );
}
