import { useSyndicatesGet } from "@internal/rest/generated/queries/syndicates";
import { useContext } from "react";
import { CreateDealContext } from "screens/create-deal/context";
import { useAuth } from "services/auth/AuthProvider";

export function useSyndicateSection() {
  const { currentDraft } = useContext(CreateDealContext);
  const auth = useAuth();
  const syndicateResult = useSyndicatesGet(currentDraft.syndicate_id as string);
  const syndicate = syndicateResult.data;

  const { pricing_plan: pricePlan } = currentDraft;
  const { id: userId } = auth.account || {};

  const additionalLeads = syndicate?.members
    ?.filter((m) => m.account_id !== userId)
    .filter((m) => m.membership_type === "lead");

  return {
    ready: syndicateResult.isSuccess && auth.status === "authenticated",
    error: syndicateResult.error || auth.error,
    data: {
      name: syndicate?.name,
      legalName: syndicate?.legal_name,
      additionalLeads,
      pricePlan,
    },
  };
}
