import { useSyndicatesList } from "@internal/rest/generated/queries/syndicates";
import {
  SyndicateListItem,
  SyndicateRelationshipFilterParameter,
} from "@internal/rest/generated/schemas";
import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CreateDealContext } from "screens/create-deal/context";

import {
  CREATE_NEW_SYNDICATE_OPTION,
  FormFields,
  getSelectedSyndicate,
} from "../../utils";
import { computeAvailableSyndicates } from "./utils";

interface Args {
  onSelectedSyndicate: (syndicate: SyndicateListItem) => void;
}

export function useSelectSyndicateField(args: Args) {
  const { watch, clearErrors, setValue } = useFormContext();
  const { currentDraft } = useContext(CreateDealContext);

  const { isSuccess: isReady, data } = useSyndicatesList({
    relationship: SyndicateRelationshipFilterParameter.lead,
  });

  const syndicateList = data?.syndicates || [];

  const selectedSyndicateId = watch(FormFields.Syndicate);

  const isCreatingSyndicate =
    selectedSyndicateId === CREATE_NEW_SYNDICATE_OPTION;

  const draftSyndicate = currentDraft.syndicate_id || "";

  const parsedSyndicates = computeAvailableSyndicates({
    syndicates: syndicateList,
    assignedSyndicateId: draftSyndicate,
  });

  useEffect(() => {
    if (!isReady) return;

    if (!syndicateList.length) {
      setValue(FormFields.Syndicate, CREATE_NEW_SYNDICATE_OPTION);
    } else if (!selectedSyndicateId && syndicateList.length) {
      setValue(FormFields.Syndicate, syndicateList[0]?.id);
    }
  }, [isReady]);

  useEffect(() => {
    if (!isReady) return;

    if (!isCreatingSyndicate && selectedSyndicateId) {
      clearErrors(FormFields.SyndicateName);
      clearErrors(FormFields.EntityLegalName);
      setValue(FormFields.SyndicateName, "");
      setValue(FormFields.EntityLegalName, "");
    }

    const selectedSyndicate = getSelectedSyndicate(
      syndicateList,
      selectedSyndicateId || ""
    );

    if (selectedSyndicate) args.onSelectedSyndicate(selectedSyndicate);
  }, [selectedSyndicateId, isReady, syndicateList]);

  return {
    isReady,
    parsedSyndicates,
  };
}
