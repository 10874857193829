import { css } from "@@panda/css";
import { BaseCurrency, PricePlanName } from "@internal/rest/generated/schemas";

import { getFees } from "./fees";

type Props = {
  pricePlan: PricePlanName;
  currency: BaseCurrency;
};

export function FeesTable(props: Props) {
  return (
    <table className={tableStyles.table} data-testid="fees-table">
      <thead>
        <tr>
          <th className={tableStyles.header}>FEE TYPE</th>
          <th className={tableStyles.header}>COST</th>
          <th className={tableStyles.header}>INFO</th>
        </tr>
      </thead>

      <tbody>
        {getFees(props.pricePlan, props.currency).map((fee) => (
          <tr key={fee.id} data-testid={`row-${fee.id}`}>
            <td className={tableStyles.cell}>{fee.name}</td>
            <td className={tableStyles.cell}>{fee.cost}</td>
            <td className={tableStyles.cell}>{fee.info}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const tableStyles = {
  table: css({
    width: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
  }),
  header: css({
    padding: "8px",
    textAlign: "left",
    bg: "grey.minecraft",
    "&:first-child": {
      borderRadius: "8px 0px 0px 0px",
    },
    "&:last-child": {
      borderRadius: "0px 8px 0px 0px",
    },
  }),
  cell: css({
    borderColor: "grey.minecraft",
    borderBottomWidth: "1px",
    borderLeftWidth: "1px",
    borderRightWidth: "1px",
    padding: "8px",
    verticalAlign: "top",
    "&:last-child": {
      borderRadius: "0px 0px 0px 8px",
    },
  }),
};
