export function getLastPath(url?: string) {
  const currentPath = url || window.location.pathname;
  return currentPath.slice(currentPath.lastIndexOf("/") + 1);
}

export function cleanupUrl(url: string) {
  return url.replace(/^(https?:\/\/)?(www\.)?/, "");
}

export const isOdinHostedUrl = (u: string) => {
  if (!u) return false;

  try {
    const url = new URL(u);
    const hostname = url.hostname;

    if (hostname === "localhost") {
      return url.port === "3000";
    }

    if (hostname === "joinodin.com" || hostname.endsWith(".joinodin.com")) {
      return true;
    }

    return false;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (_e) {
    return false;
  }
};
