import { Draft, DraftStatus } from "@internal/rest/generated/schemas";
import { createContext } from "react";

import { StepUrls } from "./steps";

export interface CreateDealContextShape {
  draftId: string;
  currentDraft: Draft;
  onNextStep: () => void;
  onGoToStep: (step: StepUrls) => void;
}

export const CreateDealContext = createContext<CreateDealContextShape>({
  draftId: "",
  currentDraft: {
    complete: false,
    id: "",
    status: DraftStatus.draft,
  },
  onNextStep: () => undefined,
  onGoToStep: () => {},
});
