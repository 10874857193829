import { Box } from "@@panda/jsx";
import { FieldSymbol } from "components/forms/Form/FieldSymbol/FieldSymbol";
import { inputRecipe } from "components/forms/Form/FormTextField/styles";
import CurrencyInput from "react-currency-input-field";

import { useRecipientInput } from "./useRecipientInput";

interface Props {
  initialPercent: number;
  name: string;
  onPercentChange: (percent: number) => void;
}

export function RecipientInput(props: Props) {
  const { percent, onPercentChange } = useRecipientInput(props);
  const styles = inputRecipe();

  return (
    <Box position="relative">
      <CurrencyInput
        onValueChange={onPercentChange}
        value={percent}
        name={props.name}
        className={styles}
        style={{ width: "6.25rem", paddingRight: "3rem" }}
        id={props.name}
        autoComplete="off"
        decimalSeparator="."
        groupSeparator=","
        aria-label={`Percentage field for ${props.name}`}
        decimalsLimit={2}
        placeholder="0"
      />
      <FieldSymbol placement="right">%</FieldSymbol>
    </Box>
  );
}
