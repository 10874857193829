import { LegalEntityType } from "@internal/rest/generated/schemas";

export enum FormFields {
  EntityType = "type",
  // Individual fields
  Email = "email",
  FirstName = "first_name",
  LastName = "last_name",
  // Company fields
  CompanyLegalName = "company_legal_name",
  CompanyNumber = "company_number",
  CompanyTradeName = "company_trade_name",
  CompanyContactEmail = "contact_email",
  CompanyContactFirstName = "contact_first_name",
  CompanyContactLastName = "contact_last_name",
}

interface IndividualFormValues {
  [FormFields.EntityType]: typeof LegalEntityType.individual;
  [FormFields.Email]: string;
  [FormFields.FirstName]: string;
  [FormFields.LastName]: string;
  [FormFields.Email]: string;
}

interface CompanyFormValues {
  [FormFields.EntityType]: typeof LegalEntityType.business;
  [FormFields.CompanyTradeName]: string;
  [FormFields.CompanyLegalName]: string;
  [FormFields.CompanyNumber]: string;
  [FormFields.CompanyContactFirstName]: string;
  [FormFields.CompanyContactLastName]: string;
  [FormFields.CompanyContactEmail]: string;
}

export type CarryRecipient = IndividualFormValues | CompanyFormValues;
export interface RawFormValues
  extends Omit<IndividualFormValues, "type">,
    Omit<CompanyFormValues, "type"> {
  [FormFields.EntityType]: LegalEntityType;
}

export function computeFormLegalEntity(
  entity: RawFormValues
): IndividualFormValues | CompanyFormValues {
  return entity.type === "individual"
    ? {
        [FormFields.EntityType]: LegalEntityType.individual,
        [FormFields.FirstName]: entity.first_name,
        [FormFields.LastName]: entity.last_name,
        [FormFields.Email]: entity.email,
      }
    : {
        [FormFields.EntityType]: LegalEntityType.business,
        [FormFields.CompanyTradeName]: entity.company_trade_name,
        [FormFields.CompanyLegalName]: entity.company_legal_name,
        [FormFields.CompanyNumber]: entity.company_number,
        [FormFields.CompanyContactFirstName]: entity.contact_first_name,
        [FormFields.CompanyContactLastName]: entity.contact_last_name,
        [FormFields.CompanyContactEmail]: entity.contact_email,
      };
}
