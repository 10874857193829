import { css, cx } from "@@panda/css";
import { Box, HStack } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import { Form } from "components/forms/Form/Form";
import { FileUpload } from "components/interaction/FileUpload/FileUpload";
import { Variants, tabsRecipe } from "components/main/Tabs/styles";

import { inputMaxWidth } from "../../MemoMaterialsStep";
import { getNameAndLabel } from "../../utils";
import { useInvestmentAgreementField } from "./useInvestmentAgreementField";

export function InvestmentAgreementField() {
  const {
    mode,
    handleFileTabClick,
    handleLinkTabClick,
    handleFileDrop,
    resetField,
    formCtx,
  } = useInvestmentAgreementField();

  const urlSubLabel = (
    <>
      Please ensure the document is not blank and can be viewed by anyone who
      has the link.
    </>
  );

  return (
    <Box w="full" data-testid="inv-agreement-section">
      <HStack
        justifyContent="space-between"
        alignItems="center"
        w="full"
        mb="m"
      >
        <Typography variant="body" fontSize="md">
          Investment agreement*
        </Typography>

        <HStack justifyContent="flex-start" alignItems="center" w="auto">
          <Box
            className={pillButtonStyles}
            role="button"
            aria-selected={mode === "file"}
            onClick={handleFileTabClick}
            flexShrink={0}
          >
            Upload a file
          </Box>

          <Box
            className={pillButtonStyles}
            role="button"
            aria-selected={mode === "text"}
            onClick={handleLinkTabClick}
            flexShrink={0}
          >
            Provide a link
          </Box>
        </HStack>
      </HStack>

      {mode === "text" ? (
        <Form.URLField
          {...getNameAndLabel("investment_agreement")}
          label="Public link*"
          width={["full", inputMaxWidth]}
          subLabel={urlSubLabel}
        />
      ) : (
        <FileUpload
          {...getNameAndLabel("investment_agreement")}
          label=""
          ariaLabel="Investment agreement*"
          accept="application/pdf"
          formValidationError={
            formCtx.formState.errors.investment_agreement?.message
          }
          previewUrl={formCtx.watch("investment_agreement")}
          onReplace={resetField}
          onFileDrop={handleFileDrop}
        />
      )}
    </Box>
  );
}

const tabsStyles = tabsRecipe({ visual: Variants.Compact });

const pillButtonStyles = cx(
  tabsStyles.tab,
  css({
    cursor: "pointer",
    borderColor: "#383838ab!",
  })
);
