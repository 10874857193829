import { formatCurrency } from "@internal/utils/currency";
import { Form } from "components/forms/Form/Form";
import { validateAmount } from "screens/create-deal/utils";
import { DEFAULT_MIN_COMMITMENT } from "utility/deals";

import { inputMaxWidth } from "../../DealTermsStep";
import { FormFields, FormLabels } from "../../utils";
import { useMinCommitmentField } from "./useMinCommitmentField";

export function MinCommitmentField() {
  const { currencySign, isReady } = useMinCommitmentField();

  return isReady ? (
    <Form.AmountField
      label={FormLabels.MinCommitment}
      subLabel={
        <span>
          This is the minimum amount someone can invest in this deal.
          <br /> If left empty, it will default to {DEFAULT_MIN_COMMITMENT},
          which is the platform minimum.
        </span>
      }
      name={FormFields.MinCommitment}
      placeholder={formatCurrency({
        baseCurrency: null,
        num: DEFAULT_MIN_COMMITMENT,
        minimumFractionDigits: 0,
      })}
      leftSymbol={currencySign}
      inputStyle={{ width: inputMaxWidth }}
      testId="min-commitment"
      invalidMessage={`The minimum commitment must be more than ${DEFAULT_MIN_COMMITMENT}`}
      validate={(val) => {
        return validateAmount({
          required: false,
          amount: val,
          minAllowed: DEFAULT_MIN_COMMITMENT,
        });
      }}
    />
  ) : null;
}
