import { GetFormLabels } from "@internal/utils/types";
import { createNameAndLabels } from "utility/forms";
import { z } from "zod";

export const SellerSchema = z.object({
  name: z.string().min(1, { message: "Please insert a contact name" }),
  email: z.string().email({ message: "Please insert a valid contact email" }),
});

export type FormValues = z.infer<typeof SellerSchema>;
export type FormLabel = GetFormLabels<typeof getNameAndLabel>;

export const { getNameAndLabel } = createNameAndLabels(SellerSchema, {
  name: "Contact name*",
  email: "Contact email*",
});
