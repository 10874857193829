import { styled } from "@@panda/jsx";

interface Props {
  missingRecipients: boolean;
  invalidPercentages: boolean;
}

export function RecipientsSummary({
  missingRecipients,
  invalidPercentages,
}: Props) {
  return (
    <>
      <styled.p mb="0" fontWeight={500} color="grey.iron">
        Carry recipient(s)
      </styled.p>

      <styled.p fontSize="xs" fontWeight="normal" color="grey.gunsmoke" mb="2">
        Share carry with others, for their assistance and expertise with this
        investment.
        <br /> These percentages must add up to 100.
      </styled.p>

      {missingRecipients ? (
        <styled.p color="yellow.vesuvius" fontSize="xs" mb="2">
          Add at least one carry recipient if the default carry is above 0
        </styled.p>
      ) : null}

      {invalidPercentages && !missingRecipients ? (
        <styled.p color="yellow.vesuvius" fontSize="xs" mb="2">
          The sum of the carry recipients percentages must add up to 100
        </styled.p>
      ) : null}
    </>
  );
}
