import { css } from "@@panda/css";
import { Box, HStack, VStack } from "@@panda/jsx";
import { FileUploadFileRejectDetails } from "@ark-ui/react/dist/components/file-upload";
import {
  CursorArrowRaysIcon,
  DocumentArrowUpIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { Spinner } from "components/atoms/Spinner";
import { Button } from "components/main/Button";
import { Styled } from "components/styled";
import { ComponentProps, ReactNode } from "react";

import { FileUpload } from "./FileUpload";
import { UploadIcon } from "./components/UploadIcon";
import { UploadPreviewIcon } from "./components/UploadPreviewIcon";
import { FileState } from "./useFileUpload";

export type FileError = FileUploadFileRejectDetails["files"][number]["errors"];

export const fileErrMap: Record<FileError[number], string> = {
  FILE_INVALID_TYPE: "Invalid file type, only PDF are accepted",
  FILE_INVALID: "File is invalid or corrupted",
  FILE_TOO_LARGE:
    "File should be smaller than 10mb. Consider compressing the file.",
  FILE_TOO_SMALL: "File is too small",
  TOO_MANY_FILES: "Too many files",
};

type ContentMap = Record<
  "default" | "uploading" | "preview",
  {
    icon: ReactNode;
    text: ReactNode;
    subText: ReactNode;
    outsideTriggerSlot?: ReactNode;
  }
>;

export const getContentMap = ({
  fileState,
  dropzoneSublabel,
  onReplace,
}: {
  fileState: FileState | null;
  dropzoneSublabel: ComponentProps<typeof FileUpload>["dropzoneSublabel"];
  onReplace: ComponentProps<typeof FileUpload>["onReplace"];
}): ContentMap => {
  const hasFailed =
    fileState?.status === "failed" || fileState?.status === "invalid";
  return {
    default: {
      icon: <UploadIcon />,
      text: hasFailed ? (
        <VStack>
          <VStack gap="xs">
            <Styled.p fontWeight="bold">Upload failed</Styled.p>
            <Styled.p color="yellow.vesuvius">
              {fileState?.status === "failed" && <>{fileState?.reason}</>}

              {fileState?.status === "invalid" && (
                <>{fileState?.reason.map((r) => fileErrMap[r]).join(", ")}</>
              )}
            </Styled.p>
          </VStack>
          <Styled.p
            display="flex"
            flexDir="row"
            gap="xs"
            w="full"
            justifyContent="center"
          >
            <CursorArrowRaysIcon width={16} /> Click to upload another file
          </Styled.p>
        </VStack>
      ) : (
        <VStack gap="s">
          <Styled.p>
            <strong>Click to upload </strong> or drag and drop
          </Styled.p>
          <Styled.p color="grey.gunsmoke" display="flex" flexDir="row" gap="xs">
            <DocumentArrowUpIcon width={16} />
            Only PDF is accepted. Max file size is 10MB.
          </Styled.p>
        </VStack>
      ),
      subText: (
        <p
          className={css({
            fontSize: "12px",
            color: "grey.gunsmoke",
          })}
        >
          {dropzoneSublabel}
        </p>
      ),
    },
    uploading: {
      icon: <Spinner size="3rem" />,
      text: (
        <p>
          <strong> Uploading... </strong>
        </p>
      ),
      subText: (
        <p
          className={css({
            color: "grey.gunsmoke",
          })}
        >
          {fileState?.fileName}
        </p>
      ),
    },
    preview: {
      icon: <UploadPreviewIcon />,
      text: (
        <HStack justifyContent="center" alignItems="center" gap="xs">
          <EyeIcon width="1rem" /> Click to preview the uploaded file
        </HStack>
      ),
      subText: null,
      outsideTriggerSlot: (
        <Box
          position="absolute"
          bottom="20%"
          left="50%"
          transform="translateX(-50%)"
        >
          <Button
            type="button"
            variant="secondary"
            size="sm"
            onClick={onReplace}
          >
            Upload a different file
          </Button>
        </Box>
      ),
    },
  };
};
