import {
  BaseCurrency,
  DraftTermsEquityShareClass,
  InvestmentType,
  ValuationCapType,
} from "@internal/rest/generated/schemas";
import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CreateDealContext } from "screens/create-deal/context";
import { isSecondarySyndicateDeal } from "screens/create-deal/utils";

import { FormFields } from "../../utils";

export function useInvestmentTypeField() {
  const { setValue, clearErrors, watch } = useFormContext();
  const { currentDraft } = useContext(CreateDealContext);

  const currency = watch(FormFields.Currency);
  const investmentType = watch(FormFields.Instrument);

  const isLoading = [currency, investmentType].some(
    (val) => typeof val === "undefined"
  );

  useEffect(() => {
    if (investmentType === InvestmentType.equity) {
      clearErrors(FormFields.ValuationCap);
      clearErrors(FormFields.Discount);
      setValue(FormFields.ValuationCap, "");
      setValue(FormFields.ValuationCapType, ValuationCapType.none);
      setValue(FormFields.Discount, "");
    } else {
      clearErrors(FormFields.ShareClass);
      clearErrors(FormFields.SharePrice);
      clearErrors(FormFields.PreMoney);
      setValue(FormFields.SharePrice, "");
      setValue(FormFields.ShareClass, DraftTermsEquityShareClass.common);
      setValue(FormFields.PreMoney, "");
    }
  }, [investmentType]);

  useEffect(() => {
    if (
      currency !== BaseCurrency.gbp &&
      investmentType === InvestmentType.seedfast
    ) {
      setValue(FormFields.Instrument, InvestmentType.equity);
    }
  }, [currency]);

  return {
    isReady: !isLoading && !isSecondarySyndicateDeal(currentDraft),
    currency,
  };
}
