import {
  dealsGetDraft,
  getDealsGetDraftQueryKey,
} from "@internal/rest/generated/queries/drafts";
import { Draft } from "@internal/rest/generated/schemas";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useFileUpload } from "screens/deal-admin/utils";
import { useBreakpoint } from "utility/hooks/useBreakpoint";
import { isOdinHostedUrl } from "utility/url";

import { FormValues } from "../../utils";

export const useDeckField = () => {
  const router = useRouter();
  const draftId = router.query["id"] as string;
  const formCtx = useFormContext<FormValues>();
  const queryClient = useQueryClient();
  const { isLgScreenUp } = useBreakpoint("lgUp");

  const pitchDeckValue = formCtx.watch("pitch_deck");
  const hasUploadedFile = isOdinHostedUrl(pitchDeckValue);

  const [mode, setMode] = useState<"text" | "file">(() => {
    if (pitchDeckValue) return hasUploadedFile ? "file" : "text";
    return "file";
  });

  const resetPitchdeckFieldValue = async () => {
    formCtx.setValue("pitch_deck", "");
    formCtx.setValue("pitch_deck_embed", "");

    await formCtx.trigger("pitch_deck");
    await formCtx.trigger("pitch_deck_embed");
  };

  const { handleFileUpload } = useFileUpload({
    dealID: draftId,
    documentType: "PITCH_DECK",
  });

  const handleFileDrop = async (f: File) => {
    await handleFileUpload(f);
    const d = await dealsGetDraft(draftId);
    await queryClient.setQueryData(getDealsGetDraftQueryKey(draftId), d); // 1️⃣ important for latest data after upload to be set into cache
    formCtx.setValue("pitch_deck", d?.materials?.pitch_deck ?? "");
    await formCtx.trigger("pitch_deck");
  };

  const handleFileTabClick = async () => {
    const draftCache = queryClient.getQueryData<Draft>(
      getDealsGetDraftQueryKey(draftId)
    );
    const pitchDeck = draftCache?.materials?.pitch_deck ?? "";

    if (isOdinHostedUrl(pitchDeck)) {
      // 2️⃣ because its used here to check if the latest value for pitch deck was uploaded so that file state can be restored
      formCtx.setValue("pitch_deck", pitchDeck);
      await formCtx.trigger("pitch_deck");
    } else {
      await resetPitchdeckFieldValue();
    }

    setMode("file");
  };

  const handleLinkTabClick = async () => {
    const draftCache = queryClient.getQueryData<Draft>(
      getDealsGetDraftQueryKey(draftId)
    );
    const pitchDeck = draftCache?.materials?.pitch_deck ?? "";
    const pitchDeckEmbed = draftCache?.materials?.pitch_deck_embed ?? "";

    if (isOdinHostedUrl(pitchDeck)) {
      await resetPitchdeckFieldValue();
    } else {
      formCtx.setValue("pitch_deck", pitchDeck);
      formCtx.setValue("pitch_deck_embed", pitchDeckEmbed);
      await formCtx.trigger("pitch_deck");
      await formCtx.trigger("pitch_deck_embed");
    }

    setMode("text");
  };

  return {
    mode,
    formCtx,
    handleFileTabClick,
    handleLinkTabClick,
    resetPitchdeckFieldValue,
    handleFileDrop,
    pitchDeckValue,
    isLgScreenUp,
  };
};
