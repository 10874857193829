/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { fetcher } from "../../client/client";
import type {
  BadRequestResponse,
  ConflictResponse,
  InternalServerErrorResponse,
  NotAuthorisedResponse,
  NotFoundResponse,
  SyndicatesCreateBody,
  SyndicatesGetSuccessResponse,
  SyndicatesListParams,
  SyndicatesListSuccessResponse,
  SyndicatesUpdateBody,
} from "../schemas";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get a list of syndicates that the authenticated account is.

 */
export const syndicatesList = (
  params?: SyndicatesListParams,
  signal?: AbortSignal
) => {
  return fetcher<SyndicatesListSuccessResponse>({
    url: `/v3/syndicates`,
    method: "GET",
    params,
    signal,
  });
};

export const getSyndicatesListQueryKey = (params?: SyndicatesListParams) => {
  return [`/v3/syndicates`, ...(params ? [params] : [])] as const;
};

export const getSyndicatesListQueryOptions = <
  TData = Awaited<ReturnType<typeof syndicatesList>>,
  TError = NotAuthorisedResponse | InternalServerErrorResponse,
>(
  params?: SyndicatesListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof syndicatesList>>, TError, TData>
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSyndicatesListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof syndicatesList>>> = ({
    signal,
  }) => syndicatesList(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof syndicatesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SyndicatesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof syndicatesList>>
>;
export type SyndicatesListQueryError =
  | NotAuthorisedResponse
  | InternalServerErrorResponse;

export const useSyndicatesList = <
  TData = Awaited<ReturnType<typeof syndicatesList>>,
  TError = NotAuthorisedResponse | InternalServerErrorResponse,
>(
  params?: SyndicatesListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof syndicatesList>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSyndicatesListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new syndicate with the requesting account as the only lead.

 */
export const syndicatesCreate = (
  syndicatesCreateBody: SyndicatesCreateBody
) => {
  return fetcher<SyndicatesGetSuccessResponse>({
    url: `/v3/syndicates`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: syndicatesCreateBody,
  });
};

export const getSyndicatesCreateMutationOptions = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | ConflictResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof syndicatesCreate>>,
    TError,
    { data: SyndicatesCreateBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof syndicatesCreate>>,
  TError,
  { data: SyndicatesCreateBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof syndicatesCreate>>,
    { data: SyndicatesCreateBody }
  > = (props) => {
    const { data } = props ?? {};

    return syndicatesCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SyndicatesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof syndicatesCreate>>
>;
export type SyndicatesCreateMutationBody = SyndicatesCreateBody;
export type SyndicatesCreateMutationError =
  | BadRequestResponse
  | NotAuthorisedResponse
  | ConflictResponse
  | InternalServerErrorResponse;

export const useSyndicatesCreate = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | ConflictResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof syndicatesCreate>>,
    TError,
    { data: SyndicatesCreateBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof syndicatesCreate>>,
  TError,
  { data: SyndicatesCreateBody },
  TContext
> => {
  const mutationOptions = getSyndicatesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a specific syndicate that the authenticated account is a member of.

 */
export const syndicatesGet = (syndicateId: string, signal?: AbortSignal) => {
  return fetcher<SyndicatesGetSuccessResponse>({
    url: `/v3/syndicates/${syndicateId}`,
    method: "GET",
    signal,
  });
};

export const getSyndicatesGetQueryKey = (syndicateId: string) => {
  return [`/v3/syndicates/${syndicateId}`] as const;
};

export const getSyndicatesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof syndicatesGet>>,
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  syndicateId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof syndicatesGet>>, TError, TData>
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSyndicatesGetQueryKey(syndicateId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof syndicatesGet>>> = ({
    signal,
  }) => syndicatesGet(syndicateId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!syndicateId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof syndicatesGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SyndicatesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof syndicatesGet>>
>;
export type SyndicatesGetQueryError =
  | BadRequestResponse
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useSyndicatesGet = <
  TData = Awaited<ReturnType<typeof syndicatesGet>>,
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  syndicateId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof syndicatesGet>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSyndicatesGetQueryOptions(syndicateId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update a syndicate. The authenticated account must be a lead of the 
specified syndicate.

 */
export const syndicatesUpdate = (
  syndicateId: string,
  syndicatesUpdateBody: SyndicatesUpdateBody
) => {
  return fetcher<SyndicatesGetSuccessResponse>({
    url: `/v3/syndicates/${syndicateId}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: syndicatesUpdateBody,
  });
};

export const getSyndicatesUpdateMutationOptions = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof syndicatesUpdate>>,
    TError,
    { syndicateId: string; data: SyndicatesUpdateBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof syndicatesUpdate>>,
  TError,
  { syndicateId: string; data: SyndicatesUpdateBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof syndicatesUpdate>>,
    { syndicateId: string; data: SyndicatesUpdateBody }
  > = (props) => {
    const { syndicateId, data } = props ?? {};

    return syndicatesUpdate(syndicateId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SyndicatesUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof syndicatesUpdate>>
>;
export type SyndicatesUpdateMutationBody = SyndicatesUpdateBody;
export type SyndicatesUpdateMutationError =
  | BadRequestResponse
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useSyndicatesUpdate = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof syndicatesUpdate>>,
    TError,
    { syndicateId: string; data: SyndicatesUpdateBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof syndicatesUpdate>>,
  TError,
  { syndicateId: string; data: SyndicatesUpdateBody },
  TContext
> => {
  const mutationOptions = getSyndicatesUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
