import { Box, VStack } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import { Form } from "components/forms/Form/Form";
import { formLabelStyles } from "components/forms/Form/FormLabel/FormLabel";
import { AvatarEditor } from "components/interaction/AvatarEditor/AvatarEditor";

import { StepSubmit } from "../../components/StepSubmit/StepSubmit";
import { useCompanyInfoStep } from "./useCompanyInfoStep";
import { CompanyInfoSchema, FormFields, FormLabels } from "./utils";

export function CompanyInfoStep() {
  const { onSaveAvatar, avatar, onSubmit, isSubmitting, initialValues } =
    useCompanyInfoStep();

  return (
    <Box>
      <Form
        onSubmit={onSubmit}
        config={{
          schema: CompanyInfoSchema,
          defaultValues: initialValues,
        }}
      >
        <>
          <p className={formLabelStyles}>Company logo</p>

          <Typography
            variant="subtext"
            fontWeight="normal"
            color="grey.gunsmoke"
            mb="s"
          >
            The company logo will appear on the deal page.
          </Typography>

          <AvatarEditor onSaveAvatar={onSaveAvatar} avatarUrl={avatar || ""}>
            {({ avatarUrl, withError, openModal }) => (
              <AvatarEditor.PreviewBox
                avatarUrl={avatarUrl}
                withError={withError}
                openModal={openModal}
              />
            )}
          </AvatarEditor>

          <VStack gap="xl" alignItems="flex-start" mt={"2.5rem"}>
            <Form.TextField
              name={FormFields.LegalName}
              placeholder="Join Odin Limited"
              width={["full", "64!"]}
              data-testid="legal-name-text-field"
              label={FormLabels.LegalName}
              subLabel="This should be as it appears on the company register in the country of incorporation"
            />

            <Form.TextField
              name={FormFields.TradingName}
              placeholder="Odin"
              width={["full", "64!"]}
              label={FormLabels.TradingName}
            />

            <Form.URLField
              name={FormFields.CompanyUrl}
              isRequired={true}
              placeholder="joinodin.com"
              width={["full", "64!"]}
              label="Company URL*"
            />

            <Form.TextField
              name={FormFields.ContactFirstName}
              placeholder="Patrick"
              width={["full", "64!"]}
              label="Company contact first name*"
            />

            <Form.TextField
              name={FormFields.ContactEmail}
              placeholder="hello@joinodin.com"
              width={["full", "64!"]}
              label={FormLabels.ContactEmail}
              subLabel="This must be a contact email for a founder or a member of the
            executive team"
            />

            <Form.CountrySelect
              name={FormFields.Country}
              label={FormLabels.Country}
              placeholder="United Kingdom"
              width={["full", "sm"]}
              omit={["IN"]}
            />
          </VStack>
          <StepSubmit isLoading={isSubmitting} />
        </>
      </Form>
    </Box>
  );
}
