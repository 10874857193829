import {
  LegalEntityInfoBusiness,
  LegalEntityInfoIndividual,
  LegalEntityTypeUnion,
} from "@internal/rest/generated/schemas";

export function getCarryRecipientName(rawEntity: LegalEntityTypeUnion | null) {
  if (!rawEntity) return null;
  if (rawEntity.type === "business") {
    const entity = rawEntity as LegalEntityInfoBusiness;
    return entity.company_legal_name;
  } else {
    const entity = rawEntity as LegalEntityInfoIndividual;
    return `${entity.first_name} ${entity.last_name}`;
  }
}
