import { BaseCurrency } from "@internal/rest/generated/schemas";
import { getCurrencySign } from "@internal/utils/currency";
import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CreateDealContext } from "screens/create-deal/context";
import { isSecondarySyndicateDeal } from "screens/create-deal/utils";

import { FormFields } from "../../utils";

export function useAllocationField() {
  const { clearErrors, watch } = useFormContext();
  const { currentDraft } = useContext(CreateDealContext);
  const isSecondary = isSecondarySyndicateDeal(currentDraft);

  const currency = watch(FormFields.Currency);
  const totalRound = watch(FormFields.TotalRound);
  const allocation = watch(FormFields.Allocation);

  const actualAllocation = allocation ? Number(allocation) : 0;
  const actualTotalRound = totalRound ? Number(totalRound) : 0;

  useEffect(() => {
    if (!isSecondary && actualAllocation < actualTotalRound) {
      clearErrors(FormFields.Allocation);
    }
  }, [actualAllocation, actualTotalRound]);

  return {
    currencySign: getCurrencySign(currency || BaseCurrency.gbp),
    totalRound: actualTotalRound,
    isSecondary,
  };
}
