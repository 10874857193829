import { sva } from "@@panda/css";

export enum Variants {
  SoftRounded = "soft-rounded",
  Plain = "plain",
  Line = "line",
  Compact = "compact",
}

export const tabsRecipe = sva({
  slots: ["root", "tablist", "tab", "panels", "panel"],
  base: {
    root: {
      w: "full",
    },
    tablist: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "xs",
      w: "full",
    },
    tab: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      userSelect: "none",
    },
    panels: {
      w: "full",
    },
    panel: {
      p: "0",
      w: "full",
    },
  },
  variants: {
    visual: {
      [Variants.Plain]: {
        tablist: {
          gap: "l",
        },
        tab: {
          position: "relative",
          transition: "color 0.2s",
          color: "grey.gunsmoke",
          _selected: {
            color: "grey.iron",
          },
          _hover: {
            color: "grey.iron",
          },
          fontSize: { base: "ld", sm: "lg" },
          pt: { base: "m", sm: "0" },
          fontWeight: "light",
        },
      },
      [Variants.SoftRounded]: {
        tab: {
          fontSize: "sm",
          fontWeight: "normal",
          color: "grey.gunsmoke",
          borderColor: "grey.gunsmoke",
          borderWidth: "1px",
          bgColor: "transparent",
          borderRadius: "full",
          height: "1.5rem",
          px: "m",
          py: "1.25rem",
          mr: "s",
          _last: {
            mr: "0",
          },
          _selected: {
            borderColor: "white",
            color: "grey.woodsmoke",
            bgColor: "white",
            _hover: {
              borderColor: "white",
              color: "grey.woodsmoke",
              bgColor: "white",
            },
          },
          _hover: {
            borderColor: "iron",
            color: "grey.iron",
            bgColor: "grey.woodsmoke",
          },
        },
      },
      [Variants.Line]: {
        tablist: {
          borderBottomColor: "grey.dune",
          borderBottomWidth: "1px",
          paddingBottom: "1px",
        },
        tab: {
          fontWeight: 700,
          py: "0.75rem",
          px: "m",
          _selected: {
            borderBottomWidth: "2px",
            borderBottomColor: "white",
            position: "relative",
            top: "1.5px",
          },
          _active: {
            backgroundColor: "grey.woodsmoke",
          },
        },
      },
      [Variants.Compact]: {
        tablist: {
          gap: "0!",
        },
        tab: {
          fontSize: "0.85rem!",
          color: "grey.gunsmoke",
          py: "s",
          px: "s",
          fontWeight: "500",
          borderRadius: "md",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "transparent",
          lineHeight: "1.3",
          minW: "3rem",
          _selected: {
            backgroundColor: "grey.dune",
            color: "white",
          },
          _active: {
            backgroundColor: "grey.minecraft",
            color: "white",
          },
        },
      },
    },
  },
  defaultVariants: {
    visual: Variants.SoftRounded,
  },
});
