import { BaseCurrency } from "@internal/rest/generated/schemas";

export function computePricingFromCurrency(currency: BaseCurrency) {
  return {
    [BaseCurrency.gbp]: {
      fixedFee: 1000,
      feedCap: 2000,
    },
    [BaseCurrency.eur]: {
      fixedFee: 1200,
      feedCap: 2400,
    },
    [BaseCurrency.usd]: {
      fixedFee: 1400,
      feedCap: 2500,
    },
    [BaseCurrency.usdc]: {
      fixedFee: 1000,
      feedCap: 2000,
    },
  }[currency];
}

export function computeSharePriceDecimals(sharePrice: number | undefined) {
  if (!sharePrice) return 2;

  const [, decimals] = sharePrice.toString().split(".");

  if (decimals?.length === 1) return 2;
  if (decimals?.length === 3) return 4;
  if (Number(decimals?.length) > 4) return 4;

  return decimals?.length || 2;
}
