import { Box } from "@@panda/jsx";
import { Form } from "components/forms/Form/Form";

import { useStepSubmit } from "./useStepSubmit";

interface Props {
  isDisabled?: boolean;
  isLoading?: boolean;
}

export function StepSubmit({ isDisabled, isLoading = false }: Props) {
  const { inReview } = useStepSubmit();
  return (
    <Box mt="xl" width="full">
      <Form.Submit
        data-testid="next-button"
        isLoading={isLoading}
        {...(isDisabled ? { isDisabled } : {})}
      >
        {inReview ? "Update" : "Next"}
      </Form.Submit>
    </Box>
  );
}
