import { css } from "@@panda/css";
import { Form } from "components/forms/Form/Form";
import {
  getMaxDateToTwoYearsFromNow,
  isoDateStringToYYYYMMDD,
} from "utility/dates";

import { FormFields, FormLabels } from "../../utils";

export function TargetCloseField() {
  const currentDate = new Date();
  const tmr = new Date(currentDate);
  tmr.setDate(currentDate.getDate() + 1);

  return (
    <Form.DateField
      subLabel={subLabel}
      name={FormFields.TargetClose}
      label={FormLabels.TargetClose}
      className={css({ width: "16rem!" })}
      min={isoDateStringToYYYYMMDD(tmr.toISOString(), {
        fallback: "",
      })}
      max={getMaxDateToTwoYearsFromNow()}
    />
  );
}

const subLabel =
  "Deals typically take 10 working days to close, but may be longer.";
