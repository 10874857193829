import { useAuth } from "services/auth/AuthProvider";

export function useSubmitTermsModal() {
  const { account } = useAuth();
  const { first_name = "", last_name = "" } = account || {};

  return {
    data: {
      firsName: first_name,
      lastName: last_name,
    },
  };
}
