import { Form } from "components/forms/Form/Form";

import { FormFields, FormLabels } from "../../utils";

export function SEISFields() {
  return (
    <Form.CheckboxGroup
      groupLabel="Will investors be able to claim S/EIS relief?"
      groupSubLabel="Odin does not review Advance Assurances as part of our review."
      options={[
        {
          name: FormFields.SEIS,
          label: FormLabels.SEIS,
        },
        {
          name: FormFields.EIS,
          label: FormLabels.EIS,
        },
      ]}
    />
  );
}
