import { useContext } from "react";
import { CreateDealContext } from "screens/create-deal/context";

import { formatUrl } from "./utils";

export function useMaterialsSection() {
  const { currentDraft } = useContext(CreateDealContext);
  const { materials } = currentDraft;

  return {
    memoHtml: materials?.memo_html || "",
    agreement: formatUrl(materials?.investment_agreement || ""),
    deck: formatUrl(materials?.pitch_deck || ""),
    deck_embed: materials?.pitch_deck_embed
      ? formatUrl(materials?.pitch_deck_embed || "")
      : undefined,
  };
}
