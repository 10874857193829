import { HStack, VStack } from "@@panda/jsx";
import { TrashIcon } from "@heroicons/react/24/solid";
import { HydratedCarrySplit } from "@internal/rest/generated/schemas";
import { TextOverflow } from "components/display/TextOverflow/TextOverflow";
import { Typography } from "components/display/Typography/Typography";
import { Button } from "components/main/Button";

import { PercentValue } from "../../state";
import { getRecipientId } from "../../utils";
import { RecipientInput } from "../RecipientInput/RecipientInput";
import { useCarryRecipients } from "./useCarryRecipients";
import {
  computeCarryShare,
  computeDefaultCarry,
  getLegalEntityName,
} from "./utils";

interface Props {
  recipients: HydratedCarrySplit;
  defaultCarry: number;
  disabled: boolean;
  onRemoveRecipient: (email: string) => void;
  onUpdatePercent: (percent: PercentValue) => void;
}

export function CarryRecipients(props: Props) {
  const { isLgScreenUp } = useCarryRecipients();
  return (
    <VStack
      alignItems="flex-start"
      mb="1"
      pointerEvents={props.disabled ? "none" : "auto"}
      userSelect={props.disabled ? "none" : "auto"}
      opacity={props.disabled ? 0.3 : 1}
    >
      {props.recipients.map((recipient) => {
        const entityName = getLegalEntityName({
          isLargeScreen: isLgScreenUp,
          rawEntity: recipient.legal_entity,
        });

        const entityId = getRecipientId(recipient);

        return (
          <HStack
            alignItems="flex-start"
            justifyContent="space-between"
            width="full"
            maxW={["21.25rem", "25rem"]}
            key={entityId}
            pb="2"
            data-testid={`recipient-${entityId}`}
          >
            <VStack alignItems="flex-start" gap="1">
              <TextOverflow
                width="full"
                maxW="10rem"
                variant="body"
                lineHeight="tight"
                mt="1"
              >
                {entityName}
              </TextOverflow>
              <Typography variant="subtext" color="grey.gunsmoke">
                {`${computeCarryShare(
                  recipient.percentage_carry_share
                )} Carry share (${computeDefaultCarry(props.defaultCarry)})`}
              </Typography>
            </VStack>

            <HStack>
              <RecipientInput
                initialPercent={recipient.percentage_carry_share}
                name={entityName || ""}
                onPercentChange={(percent: number) =>
                  props.onUpdatePercent({
                    id: entityId,
                    amount: percent,
                  })
                }
              />
              <Button
                variant="link"
                aria-label={`Remove ${entityName}`}
                onClick={() => props.onRemoveRecipient(entityId)}
                role="button"
                data-testid={`remove-${entityId}`}
              >
                <TrashIcon color="#F98B85" width="20px" />
              </Button>
            </HStack>
          </HStack>
        );
      })}
    </VStack>
  );
}
