import { getGqlQueryKey } from "@internal/gql/client";
import { useDealsUpdateDraftReviewStatus } from "@internal/rest/generated/queries/drafts";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { StepUrls } from "screens/create-deal/steps";
import {
  isSecondarySyndicateDeal,
  isSyndicateDraft,
} from "screens/create-deal/utils";
import { handleResourceEffect } from "utility/async";
import { isPlanWithOffPlatformDeals } from "utility/pricing";

import { CreateDealContext } from "../../context";
import { computePricingFromCurrency } from "./utils";

export function useReviewSubmitStep() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const router = useRouter();
  const queryClient = useQueryClient();
  const createDealContext = useContext(CreateDealContext);

  const { currentDraft, draftId, onGoToStep } = createDealContext;
  const { pricing_plan, terms, status } = currentDraft;
  const baseCurrency = terms?.base_currency || "gbp";
  const isSyndicateDeal = isSyndicateDraft(pricing_plan);
  const isOffPlatformDeal = isPlanWithOffPlatformDeals(pricing_plan);

  const { mutateAsync: updateDraftStatus } = useDealsUpdateDraftReviewStatus();

  function onEditDeal(section: StepUrls) {
    onGoToStep(section);
  }

  async function onSubmitDeal(signature: string) {
    setIsSubmitting(true);

    await handleResourceEffect({
      async action() {
        await updateDraftStatus({
          dealDraftId: draftId,
          data: {
            status: "review",
            signature,
          },
        });

        await queryClient.invalidateQueries({
          queryKey: getGqlQueryKey("GetCreatedDeals"),
        });

        await router.push(`/create-deal/${draftId}/submitted`);
      },
      cleanup() {
        setIsSubmitting(false);
      },
      error: {
        title: "An unexpected draft problem occurred",
      },
    });
  }

  return {
    onEditDeal,
    onSubmitDeal,
    isSubmitting,
    isSubmitEnabled: status === "draft" && !isOffPlatformDeal,
    isEditDisabled: status === "archive",
    isSyndicateDeal: isSyndicateDraft(pricing_plan),
    isSecondaryDeal: isSecondarySyndicateDeal(currentDraft),
    isOffPlatformDeal,
    data: {
      isFounderDeal: !isSyndicateDeal,
      pricePlan: pricing_plan,
      pricing: computePricingFromCurrency(baseCurrency),
      companyLogo: createDealContext.currentDraft.company?.logo_url ?? "",
      currencyRaw: baseCurrency,
    },
  };
}
