import { SyndicateListItem } from "@internal/rest/generated/schemas";

interface ComputeSyndicatesOptionsArgs {
  syndicates: SyndicateListItem[];
  assignedSyndicateId: string | undefined;
}

export function computeAvailableSyndicates({
  assignedSyndicateId,
  syndicates,
}: ComputeSyndicatesOptionsArgs) {
  if (!assignedSyndicateId) return syndicates;

  const chosenSyndicate = syndicates.filter(
    ({ id }) => id === assignedSyndicateId
  );

  return chosenSyndicate.concat(
    syndicates.filter(({ id }) => id !== assignedSyndicateId)
  );
}
