import {
  DealDraftFees,
  HydratedCarrySplit,
  HydratedCarrySplitEntity,
  LegalEntityInfoBusiness,
  LegalEntityInfoIndividual,
  LegalEntityTypeUnion,
  PlatformFeeType,
} from "@internal/rest/generated/schemas";

import { FormFields } from "./useCarryStep";

export interface FormValues {
  [FormFields.DefaultCarry]: string;
  [FormFields.PlatformFeeCovered]: PlatformFeeType;
  [FormFields.SyndLeadsCommitment]: string;
}

export function getLegalEntityEmail(rawEntity: LegalEntityTypeUnion): string {
  if (rawEntity.type === "business") {
    const entity = rawEntity as LegalEntityInfoBusiness;
    return entity.contact_email || "";
  } else {
    const entity = rawEntity as LegalEntityInfoIndividual;
    return entity.email;
  }
}

interface ComputeFeesPayloadArgs {
  values: FormValues;
  recipients: HydratedCarrySplit;
}

export function computeFeesPayload({
  values,
  recipients,
}: ComputeFeesPayloadArgs): DealDraftFees {
  const payload: DealDraftFees = {
    default_carry: parseInt(values.default_carry, 10) || 0,
    syndicate_leads_commitment:
      parseInt(values.syndicate_leads_commitment || "0", 10) || 0,
    platform_fee_covered_by:
      values.platform_fee_covered_by || "investee_company",
    default_carry_split: recipients,
  };

  return payload;
}

interface FormInitialValues {
  [FormFields.PlatformFeeCovered]: PlatformFeeType;
}

export function computeInitialValues(
  draftFees: DealDraftFees | undefined
): FormInitialValues {
  const initialValues: FormInitialValues = {
    [FormFields.PlatformFeeCovered]:
      draftFees?.platform_fee_covered_by || PlatformFeeType.investors_pro_rata,
  };

  if (!draftFees) return initialValues;

  return initialValues;
}

export function getRecipientId(recipient: HydratedCarrySplitEntity) {
  if (recipient.legal_entity.type === "individual") {
    const entity = recipient.legal_entity as LegalEntityInfoIndividual;
    return entity.email;
  } else {
    const entity = recipient.legal_entity as LegalEntityInfoBusiness;
    return entity.company_number;
  }
}
