import { SyndicateLeadReferenceList } from "@internal/rest/generated/schemas";
import { z } from "zod";

interface ValidateDuplicateSyndLeadArgs {
  val: string;
  currentMembers: SyndicateLeadReferenceList;
}

export function validateDuplicateMembers({
  val,
  currentMembers,
}: ValidateDuplicateSyndLeadArgs) {
  if (!val.length) return "Please enter a valid email";

  const schema = z.string().email();
  const result = schema.safeParse(val);
  if (!result.success) return "Please enter a valid email";

  const syndLeadEmails = currentMembers.map((member) =>
    member.email?.toLowerCase()
  );

  if (syndLeadEmails.includes(val.toLowerCase()))
    return "This syndicate lead has already been added";

  return true;
}
