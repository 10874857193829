import { PlatformFeeType } from "@internal/rest/generated/schemas";
import { formatCurrency } from "@internal/utils/currency";
import { useContext } from "react";
import { CreateDealContext } from "screens/create-deal/context";

import { getCarryRecipientName } from "./utils";

export function useCarrySection() {
  const { currentDraft } = useContext(CreateDealContext);
  const { fees, terms } = currentDraft;
  const baseCurrency = terms?.base_currency || "gbp";

  return {
    defaultCarry: `${fees?.default_carry}%`,
    recipients: fees?.default_carry_split?.map((recipient) => ({
      split: `${recipient.percentage_carry_share}%`,
      name: `${getCarryRecipientName(recipient.legal_entity)}`,
    })),
    syndLeadsCommitment: formatCurrency({
      num: fees?.syndicate_leads_commitment || 0,
      baseCurrency,
      minimumFractionDigits: 2,
    }),
    areSyndLeadsCoveringFees:
      fees?.platform_fee_covered_by === PlatformFeeType.syndicate_leads,
  };
}
