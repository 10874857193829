export const USER_AGREEMENT_URL =
  "https://www.joinodin.com/legal/terms-and-conditions";

export const SL_AGREEMENT_URL =
  "https://s3.eu-central-1.amazonaws.com/joinodin.com-eu-public/legals/syndicate_lead_terms_july_2024.pdf";

export const SYNDICATE_TERMS_URL =
  "https://s3.eu-central-1.amazonaws.com/joinodin.com-eu-public/legals/syndicate_terms_October_2024.pdf";

export type FormValues = {
  signature: string;
};
