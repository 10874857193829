import { css, cx } from "@@panda/css";
import { Box, VStack } from "@@panda/jsx";
import { FileUpload as ArkFileUpload } from "@ark-ui/react";
import { FormError } from "components/forms/Form/FormError/FormError";
import { FormLabel } from "components/forms/Form/FormLabel/FormLabel";
import { FormSubLabel } from "components/forms/Form/FormSubLabel/FormSubLabel";
import { ComponentProps } from "react";

import { getContentMap } from "./helpers";
import { styles } from "./styles";
import { useFileUpload } from "./useFileUpload";

// https://ark-ui.com/react/docs/components/file-upload

type Props = {
  name?: string;
  label?: string;
  ariaLabel?: string;
  subLabel?: string | React.ReactNode;
  formValidationError?: string | null | undefined;
  previewUrl?: string | undefined;
  accept?: ComponentProps<typeof ArkFileUpload.Root>["accept"];
  dropzoneSublabel?: string;
  onReplace: () => Promise<unknown>;
  onFileDrop: (file: File) => Promise<unknown>;
};

export function FileUpload(props: Props) {
  const {
    fileState,
    hasPreview,
    isUploading,
    contentStatus,
    handleFileAccept,
    handleFileReject,
  } = useFileUpload({
    previewUrl: props.previewUrl,
    onFileDrop: props.onFileDrop,
  });

  const TriggerOrAnchor = hasPreview ? "a" : ArkFileUpload.Trigger;
  const contentMap = getContentMap({
    fileState,
    dropzoneSublabel: props.dropzoneSublabel,
    onReplace: props.onReplace,
  });

  return (
    <div className={css({ w: "full", h: "full", position: "relative" })}>
      {contentMap[contentStatus].outsideTriggerSlot}

      <ArkFileUpload.Root
        accept={props.accept ?? []}
        maxFiles={1}
        maxFileSize={10000000}
        disabled={isUploading || hasPreview}
        allowDrop={!isUploading || !hasPreview}
        onFileAccept={handleFileAccept}
        onFileReject={handleFileReject}
      >
        <ArkFileUpload.Context>
          {() => {
            return (
              <>
                <Box>
                  {props.label && (
                    <ArkFileUpload.Label asChild>
                      <FormLabel
                        htmlFor={props.name}
                        withSubLabel={Boolean(props.subLabel)}
                      >
                        {props.label}
                      </FormLabel>
                    </ArkFileUpload.Label>
                  )}

                  {props.subLabel && (
                    <FormSubLabel>{props.subLabel}</FormSubLabel>
                  )}
                </Box>

                <ArkFileUpload.Dropzone
                  className={styles.dropzone}
                  data-disabled={isUploading || hasPreview ? true : undefined}
                  data-testid="drop-zone"
                >
                  <TriggerOrAnchor
                    className={cx(
                      styles.trigger,
                      hasPreview
                        ? css({ justifyContent: "flex-start!", pt: "3xl" })
                        : undefined
                    )}
                    disabled={isUploading || hasPreview ? true : undefined}
                    {...(hasPreview && {
                      href: props.previewUrl,
                      target: "_blank",
                      rel: "noreferrer",
                    })}
                  >
                    {contentMap[contentStatus].icon}

                    <VStack gap="0.25rem">
                      {contentMap[contentStatus].text}
                      {contentMap[contentStatus].subText}
                    </VStack>
                  </TriggerOrAnchor>
                </ArkFileUpload.Dropzone>

                <ArkFileUpload.HiddenInput aria-label={props.ariaLabel} />
              </>
            );
          }}
        </ArkFileUpload.Context>

        <FormError>{props.formValidationError}</FormError>
      </ArkFileUpload.Root>
    </div>
  );
}
