import {
  BaseCurrency,
  HydratedCarrySplit,
  PlatformFeeType,
} from "@internal/rest/generated/schemas";
import { useContext, useState } from "react";
import { CreateDealContext } from "screens/create-deal/context";
import { useBooleanToggle } from "utility/hooks/useBooleanToggle";

import { CarryRecipient } from "./components/AddRecipientModal/utils";
import { computeInitialValues, getRecipientId } from "./utils";

export interface PercentValue {
  id: string;
  amount: number;
}

export function useCarryStepState() {
  const { currentDraft } = useContext(CreateDealContext);

  const initialDefaultCarry =
    currentDraft.fees?.default_carry !== undefined
      ? String(currentDraft.fees?.default_carry)
      : "";

  const [carryRecipients, setCarryRecipients] = useState<HydratedCarrySplit>(
    currentDraft.fees?.default_carry_split || []
  );

  const [defaultCarry, setDefaultCarry] = useState<string>(initialDefaultCarry);

  const [platformFeesPayee, setPlatformFeesPayee] = useState<PlatformFeeType>(
    currentDraft.fees?.platform_fee_covered_by ||
      PlatformFeeType.syndicate_leads
  );

  const [isSubmitting, toggleSubmitting] = useBooleanToggle();

  function onUpdateRecipientShare(percent: PercentValue) {
    const updated = carryRecipients.map((recipient) => {
      const recipientId = getRecipientId(recipient);
      return percent.id === recipientId
        ? {
            ...recipient,
            percentage_carry_share: percent.amount,
          }
        : recipient;
    });

    setCarryRecipients(updated);
  }

  function onAddRecipient(recipient: CarryRecipient) {
    setCarryRecipients([
      ...carryRecipients,
      {
        legal_entity: {
          ...recipient,
        },
        percentage_carry_share: 0,
      },
    ]);
  }

  function onRemoveRecipient(id: string) {
    const updatedRecipients = carryRecipients.filter((recipient) => {
      const recipientId = getRecipientId(recipient);
      return recipientId !== id;
    });

    setCarryRecipients(updatedRecipients);
  }

  function onFieldsChange(fields: string[] | null) {
    if (!fields) return;

    const newDefaultCarry = fields[0] as string;
    const newPlatformFeesPayee = fields[1] as PlatformFeeType;

    if (newDefaultCarry !== defaultCarry) {
      setDefaultCarry(newDefaultCarry);
    }

    if (newPlatformFeesPayee !== platformFeesPayee) {
      setPlatformFeesPayee(newPlatformFeesPayee);
    }
  }

  const arePercents100 =
    carryRecipients.reduce((acc, p) => {
      return acc + p.percentage_carry_share;
    }, 0) === 100;

  return {
    onUpdateRecipientShare,
    onRemoveRecipient,
    onAddRecipient,
    onFieldsChange,
    toggleSubmitting,
    state: {
      initialValues: {
        form: computeInitialValues(currentDraft.fees),
        defaultCarry,
        commitment:
          currentDraft.fees?.syndicate_leads_commitment !== undefined
            ? String(currentDraft.fees.syndicate_leads_commitment)
            : "",
      },
      currency: currentDraft.terms?.base_currency || BaseCurrency.gbp,
      carryRecipients,
      defaultCarry,
      platformFeesPayee,
      isSubmitting,
      arePercents100,
    },
  };
}
