import { css } from "@@panda/css";

export const styles = {
  files: css({
    display: "flex",
    flexDir: "column",
    w: "full",
    gap: "1rem",
  }),
  file: css({
    display: "flex",
    flexDir: "row",
    w: "full",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "md",
    p: "1rem",
    borderColor: "grey.dune",
    borderWidth: "1px",
    "&:first-child": {
      mt: "1rem",
    },
  }),
  dropzone: css({
    w: "100%",
    h: "100%",
    height: "225px",
    transition: "all 0.2s",
    borderRadius: "md",
    borderStyle: "dashed",
    borderColor: "grey.dune",
    borderWidth: "1px",
    backgroundColor: "grey.woodsmoke",

    "[data-dragging] &": {
      backgroundColor: "grey.dune",
    },
    _hover: {
      backgroundColor: "grey.dune",
    },

    _disabled: {
      backgroundColor: "grey.woodsmoke",
      _hover: {
        backgroundColor: "grey.woodsmoke",
      },
    },
  }),
  trigger: css({
    w: "100%",
    h: "100%",
    display: "flex",
    flexDir: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.75rem",
  }),
  label: css({
    marginBottom: "1rem",
  }),
  icon: css({
    w: "48px",
    h: "48px",
  }),
};
