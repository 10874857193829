import {
  getDealsGetDraftQueryKey,
  useDealsUpdateDraft,
} from "@internal/rest/generated/queries/drafts";
import { PlatformFeeType } from "@internal/rest/generated/schemas";
import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { CreateDealContext } from "screens/create-deal/context";
import { isSecondarySyndicateDeal } from "screens/create-deal/utils";
import { handleResourceEffect } from "utility/async";
import { useBooleanToggle } from "utility/hooks/useBooleanToggle";

import { FormValues } from "./helpers";

export const usePricingStep = () => {
  const queryClient = useQueryClient();
  const { currentDraft, draftId, onNextStep } = useContext(CreateDealContext);
  const selectedPricePlan = currentDraft.pricing_plan;
  const isSecondary = isSecondarySyndicateDeal(currentDraft);

  const [isSubmitting, toggleSubmitting] = useBooleanToggle();

  const { mutateAsync: updateDraft } = useDealsUpdateDraft();
  async function onSubmit(values: FormValues) {
    toggleSubmitting();

    await handleResourceEffect({
      async action() {
        await updateDraft({
          dealDraftId: draftId,
          data: {
            fees: {
              platform_fee_covered_by: values.platformFeeCovered,
            },
          },
        });

        await queryClient.resetQueries({
          queryKey: getDealsGetDraftQueryKey(draftId),
          exact: true,
        });

        onNextStep();
      },
      error: {
        title: "An unexpected draft problem occurred",
      },
      cleanup() {
        toggleSubmitting();
      },
    });
  }

  const defaultValues = {
    platformFeeCovered:
      currentDraft.fees?.platform_fee_covered_by ||
      PlatformFeeType.investors_pro_rata,
  } satisfies FormValues;

  return {
    isSubmitting,
    onSubmit,
    selectedPricePlan,
    currency: currentDraft.terms?.base_currency,
    isSecondary,
    defaultValues,
  };
};
