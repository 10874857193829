import { Center, Flex, HStack, HstackProps } from "@@panda/jsx";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Skeleton } from "components/main/Skeleton";
import { Tooltip } from "components/main/Tooltip";

import { CopyText } from "../../interaction/CopyText/CopyText";
import { Typography } from "../Typography/Typography";

export interface FieldRowProps extends HstackProps {
  copy?: boolean;
  label: string | React.ReactNode;
  value: string | React.ReactNode;
  tooltipInfo?: string;
  testId?: string;
  isLoading?: boolean;
}

export function FieldRow({
  copy = false,
  label,
  value,
  tooltipInfo,
  testId,
  isLoading = false,
  ...rest
}: FieldRowProps) {
  return (
    <HStack
      gap="s"
      justifyContent="space-between"
      my="0.25rem"
      width="full"
      {...(testId ? { "data-testid": testId } : null)}
      {...rest}
    >
      {typeof label === "string" ? (
        <Typography variant="body" color="grey.gunsmoke">
          {label}
        </Typography>
      ) : (
        label
      )}
      <Flex padding="2">
        {isLoading ? (
          <Skeleton width="8rem" />
        ) : copy &&
          typeof value === "string" &&
          typeof label === "string" &&
          !tooltipInfo ? (
          <CopyText label={value} text={value} toastLabel={label} showIcon />
        ) : (
          <HStack gap="3" alignItems="center">
            {typeof value === "string" ? (
              <Typography variant="body" textAlign="right">
                {value}
              </Typography>
            ) : (
              value
            )}
            {tooltipInfo && (
              <Tooltip label={tooltipInfo}>
                <Center height="1.3em" width="1.3em">
                  <InformationCircleIcon width="1rem" color="grey.alabaster" />
                </Center>
              </Tooltip>
            )}
          </HStack>
        )}
      </Flex>{" "}
    </HStack>
  );
}
