import { css } from "@@panda/css";
import { Box } from "@@panda/jsx";
import { ArrowUpCircleIcon, DocumentIcon } from "@heroicons/react/24/solid";

export function UploadIcon() {
  return (
    <Box aria-hidden className={css({ position: "relative" })}>
      <DocumentIcon
        className={css({
          w: "48px",
          h: "48px",
        })}
      />
      <ArrowUpCircleIcon
        className={css({
          color: "brand.yamcha",
          position: "absolute",
          right: 0,
          bottom: 0,
          w: "20px",
          h: "20px",
        })}
      />
    </Box>
  );
}
