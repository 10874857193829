import {
  getDealsGetDraftQueryKey,
  useDealsUpdateDraft,
} from "@internal/rest/generated/queries/drafts";
import { DraftMaterials } from "@internal/rest/generated/schemas";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { Flags, useFeatureFlag } from "services/feature-flags";
import { handleResourceEffect } from "utility/async";
import { useBooleanToggle } from "utility/hooks/useBooleanToggle";

import { CreateDealContext } from "../../context";
import {
  DEFAULT_TEMPLATE,
  buildMaterialVariables,
  getNameAndLabel,
} from "./utils";

export function useMemoMaterialsStep() {
  const queryClient = useQueryClient();
  const createDealContext = useContext(CreateDealContext);
  const isDocUploadEnabled = useFeatureFlag(Flags.DOC_UPLOAD);

  const currentMaterials = createDealContext.currentDraft.materials;
  const initialMemo = currentMaterials?.memo_html ?? DEFAULT_TEMPLATE;

  const [currentMemo, setMemo] = useState<string>(initialMemo);
  const [isSubmitting, toggleSubmitting] = useBooleanToggle();
  const { mutateAsync: updateDraft } = useDealsUpdateDraft();

  async function onEditMemo(memo: string) {
    setMemo(memo);
  }

  async function onSubmit(values: DraftMaterials) {
    toggleSubmitting();

    await handleResourceEffect({
      async action() {
        await updateDraft({
          dealDraftId: createDealContext.draftId,
          data: {
            materials: buildMaterialVariables({ memo: currentMemo, values }),
          },
        });

        await queryClient.resetQueries({
          queryKey: getDealsGetDraftQueryKey(createDealContext.draftId),
          exact: true,
        });

        createDealContext.onNextStep();
      },
      error: {
        title: "An unexpected draft problem occurred",
      },
      cleanup() {
        toggleSubmitting();
      },
    });
  }

  return {
    initialValues: {
      memo: initialMemo,
      form: {
        [getNameAndLabel("pitch_deck").name]:
          createDealContext.currentDraft.materials?.pitch_deck ?? "",
        [getNameAndLabel("investment_agreement").name]:
          createDealContext.currentDraft.materials?.investment_agreement ?? "",
        [getNameAndLabel("pitch_deck_embed").name]:
          createDealContext.currentDraft.materials?.pitch_deck_embed ?? "",
      },
    },
    onSubmit,
    onEditMemo,
    currentMemo,
    isSubmitting,
    isDocUploadEnabled,
  };
}
