import { getUrlFieldFormSchema } from "utility/forms";
import { z } from "zod";

export enum FormFields {
  LegalName = "legal_name",
  CompanyUrl = "company_url",
  ContactFirstName = "contact_first_name",
  ContactEmail = "contact_email",
  TradingName = "trading_name",
  Country = "country",
}

export enum FormLabels {
  LegalName = "Company legal name*",
  CompanyUrl = "Company URL*",
  TradingName = "Company trading name*",
  Country = "Country of Incorporation*",
  ContactEmail = "Company contact email address*",
  ContactName = "Company contact first name*",
}

export const CompanyInfoSchema = z.object({
  [FormFields.LegalName]: z
    .string()
    .min(2, "Company legal name must contain at least 2 character(s)"),
  [FormFields.CompanyUrl]: getUrlFieldFormSchema({ required: true }),
  [FormFields.ContactFirstName]: z
    .string()
    .min(2, "Company contact must contain at least 2 character(s)"),
  [FormFields.ContactEmail]: z.string().email(),
  [FormFields.TradingName]: z
    .string()
    .min(2, "Company trading name must contain at least 2 character(s)"),
  [FormFields.Country]: z
    .string()
    .min(2, "Country must contain at least 2 character(s)"),
});

export type CompanyInfoSchemaType = z.infer<typeof CompanyInfoSchema>;
