import { css } from "@@panda/css";
import { Box } from "@@panda/jsx";
import { DocumentTextIcon } from "@heroicons/react/24/solid";

export function UploadPreviewIcon() {
  return (
    <Box aria-hidden className={css({ position: "relative" })}>
      <DocumentTextIcon
        className={css({
          w: "48px",
          h: "48px",
          color: "brand.yamcha",
        })}
      />
    </Box>
  );
}
