import { css, cx } from "@@panda/css";
import { Box, HStack, VStack } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import { Form } from "components/forms/Form/Form";
import { FileUpload } from "components/interaction/FileUpload/FileUpload";
import { Link } from "components/interaction/Link";
import { Button } from "components/main/Button";
import { Dialog } from "components/main/Dialog";
import { Variants, tabsRecipe } from "components/main/Tabs/styles";

import { inputMaxWidth } from "../../MemoMaterialsStep";
import { getNameAndLabel } from "../../utils";
import { useDeckField } from "./useDeckField";

export function DeckField() {
  const {
    mode,
    formCtx,
    isLgScreenUp,
    pitchDeckValue,
    handleFileTabClick,
    handleLinkTabClick,
    resetPitchdeckFieldValue,
    handleFileDrop,
  } = useDeckField();

  return (
    <Box w="full" data-testid="pitch-deck-section">
      <HStack
        justifyContent="space-between"
        alignItems="center"
        w="full"
        mb="m"
      >
        <Typography variant="body" fontSize="md">
          Pitch deck*
        </Typography>

        <HStack justifyContent="flex-start" alignItems="center" w="auto!">
          <Box
            className={pillButtonStyles}
            flexShrink={0}
            role="button"
            aria-selected={mode === "file"}
            onClick={handleFileTabClick}
          >
            Upload a file
          </Box>

          <Box
            className={pillButtonStyles}
            flexShrink={0}
            role="button"
            aria-selected={mode === "text"}
            onClick={handleLinkTabClick}
          >
            Provide a link
          </Box>
        </HStack>
      </HStack>

      {mode === "text" ? (
        <VStack alignItems="flex-start" gap="2rem" w="full">
          <Form.URLField
            {...getNameAndLabel("pitch_deck")}
            label="Public link*"
            width={["full", inputMaxWidth]}
            subLabel={
              <>
                Please ensure the document is not blank and can be viewed by
                anyone who has the link
              </>
            }
          />

          <Form.URLEmbed
            {...getNameAndLabel("pitch_deck_embed")}
            label="Embedded deck URL"
            width={["full", inputMaxWidth]}
            subLabel={
              <Typography variant="subtext" color="grey.gunsmoke">
                Link to the embeddable form of your deck.{" "}
                <Link
                  href="https://help.joinodin.com/hc/en-gb/articles/25326486288273-Drafting-a-primary-deal"
                  isExternal
                >
                  Learn more
                </Link>
                .
              </Typography>
            }
          />
        </VStack>
      ) : (
        <>
          <FileUpload
            {...getNameAndLabel("pitch_deck")}
            label=""
            ariaLabel="Pitch deck*"
            accept="application/pdf"
            formValidationError={formCtx.formState.errors.pitch_deck?.message}
            previewUrl={pitchDeckValue}
            onReplace={resetPitchdeckFieldValue}
            onFileDrop={handleFileDrop}
          />

          <Box w="full">
            {pitchDeckValue ? (
              isLgScreenUp ? (
                <>
                  <Typography variant="subtext" color="grey.gunsmoke" my="m">
                    Preview:{" "}
                  </Typography>

                  <iframe
                    title="Deck preview"
                    width="100%"
                    height="400px"
                    src={pitchDeckValue}
                    data-testid="deck-embed-iframe"
                  />
                </>
              ) : (
                <Dialog title="Deck embed preview">
                  <Dialog.Trigger>
                    <Button w="full" variant="secondary" mt="s">
                      Preview
                    </Button>
                  </Dialog.Trigger>
                  <Dialog.Content>
                    <iframe
                      title="Deck preview"
                      width="100%"
                      height="400px"
                      src={pitchDeckValue}
                      data-testid="deck-embed-iframe"
                    />
                  </Dialog.Content>
                </Dialog>
              )
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
}

const tabsStyles = tabsRecipe({ visual: Variants.Compact });
const pillButtonStyles = cx(
  tabsStyles.tab,
  css({
    cursor: "pointer",
    borderColor: "#383838ab!",
  })
);
