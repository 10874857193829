import {
  getDealsGetDraftQueryKey,
  useDealsUpdateDraft,
} from "@internal/rest/generated/queries/drafts";
import {
  getSyndicatesListQueryKey,
  useSyndicatesCreate,
  useSyndicatesUpdate,
} from "@internal/rest/generated/queries/syndicates";
import {
  APIError,
  BaseCurrency,
  PricePlanName,
  SubscriptionTier,
  SyndicateLeadReference,
  SyndicateLeadReferenceList,
  SyndicateListItem,
} from "@internal/rest/generated/schemas";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { CreateDealContext } from "screens/create-deal/context";
import { toast } from "services/toast";
import { useBooleanToggle } from "utility/hooks/useBooleanToggle";
import {
  isActivePricing,
  isBrandedPricePlan,
  mapSubscriptionToPricePlan,
} from "utility/pricing";

import {
  CREATE_NEW_SYNDICATE_OPTION,
  FormFields,
  FormValues,
  shouldNotAllowPricePlan,
  shouldShowEntityLegalNameField,
} from "./utils";

const defaultPricePlan = PricePlanName.syndicate_2024;

export function useSelectSyndicateStep() {
  const queryClient = useQueryClient();
  const createDealContext = useContext(CreateDealContext);
  const draft = createDealContext.currentDraft;

  const { mutateAsync: updateDraft } = useDealsUpdateDraft();
  const { mutateAsync: updateSyndicate } = useSyndicatesUpdate();
  const { mutateAsync: createSyndicate } = useSyndicatesCreate();

  const draftSyndicate = createDealContext.currentDraft.syndicate_id || "";

  const [syndicateId, setSyndicateId] = useState<string>(draftSyndicate);
  const [syndicate, setSyndicate] = useState<SyndicateListItem | null>(null);
  const [members, setMembers] = useState<SyndicateLeadReferenceList>([]);
  const [hasAddedMembers, toggleAddedMembers] = useBooleanToggle();
  const [isSubmitting, toggleSubmitting] = useBooleanToggle();

  const [pricePlan, setPricePlan] = useState<PricePlanName>(() => {
    // SafeCast: draft can't be created without a pricing plan
    const dealPP = draft.pricing_plan as PricePlanName;
    return isActivePricing(dealPP) ? dealPP : defaultPricePlan;
  });

  const syndicatePlan = syndicate
    ? mapSubscriptionToPricePlan(syndicate.subscription_tier)
    : null;

  function onFieldsChange(fields: string[] | null) {
    if (!fields) return;

    const newSyndicateId = fields[0];

    if (newSyndicateId !== syndicateId) {
      setSyndicateId(newSyndicateId || "");
      setMembers([]);
    }
  }

  async function onSubmit(values: FormValues) {
    const currSyndicateId = values.syndicate;
    const isCreatingSyndicate = currSyndicateId === CREATE_NEW_SYNDICATE_OPTION;
    const currentLegalName = values[FormFields.EntityLegalName];
    const areLeadsEditable = syndicate?.leads_mutability === "mutable";
    const isLegalNameEditable = syndicate?.legal_name_mutability === "mutable";
    const isSubscribed = Boolean(syndicate?.is_subscribed);
    const targetPricePlan = isSubscribed
      ? // SafeCast: our API contract makes sure `subscription_tier` is set if `is_subscribed`
        mapSubscriptionToPricePlan(
          syndicate?.subscription_tier as SubscriptionTier
        )
      : pricePlan;

    const withNewLegalName =
      Boolean(currentLegalName) &&
      isLegalNameEditable &&
      isBrandedPricePlan(pricePlan);

    toggleSubmitting();

    try {
      let createdSyndicateId: string | undefined;

      if (isCreatingSyndicate) {
        const res = await createSyndicate({
          data: {
            name: values[FormFields.SyndicateName],
            leads: members,
            ...(isBrandedPricePlan(pricePlan)
              ? { legal_name: values[FormFields.EntityLegalName] }
              : null),
          },
        });

        createdSyndicateId = res.id;
      } else if (withNewLegalName || areLeadsEditable) {
        await updateSyndicate({
          syndicateId: currSyndicateId,
          data: {
            ...(areLeadsEditable ? { leads: members } : null),
            ...(withNewLegalName ? { legal_name: currentLegalName } : null),
          },
        });
      }

      const draftSyndicateId = createdSyndicateId || currSyndicateId;

      await updateDraft({
        dealDraftId: createDealContext.draftId,
        data: {
          syndicate_id: draftSyndicateId,
          pricing_plan: targetPricePlan,
        },
      });

      await queryClient.resetQueries({
        queryKey: getDealsGetDraftQueryKey(createDealContext.draftId),
        exact: true,
      });

      await queryClient.resetQueries({
        queryKey: getSyndicatesListQueryKey(),
        exact: true,
      });

      createDealContext.onNextStep();
    } catch (e: unknown) {
      const err = e as APIError;

      toggleSubmitting();

      const isDuplicateNameError = err.message
        ? err.message.includes("this name already exists")
        : false;

      const errMessage = isDuplicateNameError
        ? "The supplied syndicate name is already in use. Please choose another one"
        : `${err.message}. In case the error persists contact us at hello@joinodin.com`;

      toast.show({
        status: "error",
        title: isDuplicateNameError
          ? "Syndicate Name Error"
          : "Unexpected Error",
        desc: errMessage,
      });
    }
  }

  function onPricePlanChange(pricePlan: PricePlanName) {
    setPricePlan(pricePlan);
  }

  function onResetMembers(newMembers: SyndicateLeadReferenceList) {
    // do not overwrite existing added members
    if (hasAddedMembers) return;
    setMembers(newMembers);
  }

  function onAddMember(member: SyndicateLeadReference) {
    setMembers((members) => [...members, member]);
    toggleAddedMembers();
  }

  function onRemoveMember(member: SyndicateLeadReference) {
    setMembers((members) => members.filter((l) => l.email !== member.email));
    toggleAddedMembers();
  }

  function handleSelectedSyndicate(syndicate: SyndicateListItem) {
    setSyndicate(syndicate);

    const pricePlan = mapSubscriptionToPricePlan(syndicate.subscription_tier);
    const isActivePlan = isActivePricing(pricePlan);
    setPricePlan(isActivePlan ? pricePlan : defaultPricePlan);
  }

  return {
    onPricePlanChange,
    onSubmit,
    onFieldsChange,
    onResetMembers,
    onAddMember,
    onRemoveMember,
    showEntityLegalNameField: shouldShowEntityLegalNameField({
      pricePlan,
      syndicateId,
      syndicate,
    }),
    initialValues: {
      [FormFields.Syndicate]: createDealContext.currentDraft.syndicate_id || "",
      [FormFields.SyndicateName]: "",
      [FormFields.EntityLegalName]: syndicate?.legal_name || "",
    },
    formValues: {
      syndicateId,
      pricePlan,
    },
    handleSelectedSyndicate,
    isSubmitting,
    isPlanRequiringCsApproval: shouldNotAllowPricePlan({
      selectedPrice: pricePlan,
      syndicatePlan: syndicatePlan || defaultPricePlan,
    }),
    data: {
      members,
      syndicate,
      syndicatePlan,
      currency:
        createDealContext.currentDraft.terms?.base_currency || BaseCurrency.gbp,
    },
  };
}
