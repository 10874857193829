import { token } from "@@panda/tokens";
import { ComponentProps } from "react";

export function EditIcon({ onClick, ...rest }: ComponentProps<"button">) {
  return (
    <button onClick={onClick} {...rest}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.75 19.75L9 18.75L18.2929 9.45711C18.6834 9.06658 18.6834 8.43342 18.2929 8.04289L16.4571 6.20711C16.0666 5.81658 15.4334 5.81658 15.0429 6.20711L5.75 15.5L4.75 19.75Z"
          stroke={token("colors.brand.krilin")}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.25 19.75H13.75"
          stroke={token("colors.brand.krilin")}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}
