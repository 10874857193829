import { SyndicateLeadReference } from "@internal/rest/generated/schemas";
import { useDialogCtx } from "components/main/Dialog/useDialogCtx";

export enum FormFields {
  FirstName = "first_name",
  LastName = "last_name",
  Email = "email",
}

export enum FormLabels {
  FirstName = "First name",
  LastName = "Last name",
  Email = "Email",
}

interface Args {
  onAdd: (member: SyndicateLeadReference) => void;
}

export function useAddMemberForm({ onAdd }: Args) {
  const { handleClose } = useDialogCtx();

  function onSubmit(values: SyndicateLeadReference) {
    onAdd(values);
    handleClose();
  }

  return {
    onClose: handleClose,
    onSubmit,
  };
}
