import { HStack } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";

import { EditIcon } from "./EditIcon";

interface Props {
  title: string;
  onEditClick?: (() => void) | null | undefined;
}

export function ReviewSectionHeading(props: Props) {
  return (
    <HStack width="full" justifyContent="space-between" mb="2">
      <Typography variant="heading.3">{props.title}</Typography>

      {props.onEditClick ? (
        <EditIcon
          onClick={props.onEditClick}
          aria-label={`Edit ${props.title}`}
        />
      ) : null}
    </HStack>
  );
}
