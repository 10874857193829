interface ComputeTaxReliefTextArgs {
  seis: boolean;
  eis: boolean;
}

export function computeTaxReliefText({ eis, seis }: ComputeTaxReliefTextArgs) {
  if (!seis && !eis) return "No";
  if (seis && !eis) return "SEIS";
  if (!seis && eis) return "EIS";
  return "SEIS / EIS";
}
