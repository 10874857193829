import { Box } from "@@panda/jsx";
import { Form } from "components/forms/Form/Form";
import { Dialog } from "components/main/Dialog";
import { StepSubmit } from "screens/create-deal/components/StepSubmit/StepSubmit";
import { DEFAULT_PRICE_PLAN } from "utility/deals";
import { isBrandedPricePlan } from "utility/pricing";

import { AddMemberForm } from "./components/AddMemberForm/AddMemberForm";
import { AdditionalMembers } from "./components/AdditionalMembers/AdditionalMembers";
import { LegalNameField } from "./components/LegalNameField/LegalNameField";
import { PricePlans } from "./components/PricePlans/PricePlans";
import { SelectSyndicateField } from "./components/SelectSyndicateField/SelectSyndicateField";
import { SyndicateNameField } from "./components/SyndicateNameField/SyndicateNameField";
import { useSelectSyndicateStep } from "./useSelectSyndicateStep";
import { watchedFields } from "./utils";

export const inputMaxWidth = "16rem";

export function SelectSyndicateStep() {
  const {
    onResetMembers,
    onAddMember,
    onRemoveMember,
    onPricePlanChange,
    onSubmit,
    onFieldsChange,
    formValues: { syndicateId, pricePlan },
    initialValues,
    handleSelectedSyndicate,
    isSubmitting,
    isPlanRequiringCsApproval,
    data: { currency, members, syndicatePlan },
    showEntityLegalNameField,
  } = useSelectSyndicateStep();

  const isLockedPlan = syndicatePlan
    ? isBrandedPricePlan(syndicatePlan)
    : false;

  // Notes:
  //  1. the modal needs to be top-level given it contains a nested form (to add a new member)
  //  2. `react-hook-form` does not support nested forms, hence we need to render the add member
  //     form outside of the original form context for the step

  return (
    <Box data-testid="select-syndicate-step">
      <Dialog title="Add new syndicate lead">
        <Form
          onSubmit={onSubmit}
          onFieldsChange={onFieldsChange}
          fieldsToWatch={watchedFields}
          config={{ defaultValues: initialValues }}
        >
          <SelectSyndicateField onSelectedSyndicate={handleSelectedSyndicate} />
          <>
            <SyndicateNameField />
            <AdditionalMembers
              syndicateId={syndicateId}
              members={members}
              onRemoveMember={onRemoveMember}
              onResetMembers={onResetMembers}
            />
          </>
          <PricePlans
            active={pricePlan}
            syndicatePlan={syndicatePlan || DEFAULT_PRICE_PLAN}
            currency={currency}
            isLockedPlan={isLockedPlan}
            onPricePlanChange={onPricePlanChange}
            currentSyndicateId={syndicateId}
            isPlanRequiringCsApproval={isPlanRequiringCsApproval}
          />
          {showEntityLegalNameField && !isPlanRequiringCsApproval ? (
            <LegalNameField />
          ) : null}
          {isPlanRequiringCsApproval ? null : (
            <StepSubmit isLoading={isSubmitting} />
          )}
        </Form>
        <Dialog.Content>
          <Box>
            <AddMemberForm currentMembers={members} onAdd={onAddMember} />
          </Box>
        </Dialog.Content>
      </Dialog>
    </Box>
  );
}
